<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { page } from '$app/stores';
	import { type PopupSettings, popup } from '@skeletonlabs/skeleton';

	import { tippy } from '../../../../../../../actions';
	import { PopupWrapper } from '../../../../../elements';
	import DeleteButton from './DeleteButton.svelte';
	import ReportButton from './ReportButton.svelte';

	// Props...
	export let feedItemId: string;
	export let feedItemType: FeedItemType;
	export let authorId: null | string;

	// State...
	let displayReportConfirmationButtons: boolean = false;
	let displayDeleteConfirmationButtons: boolean = false;

	function resetConfirmationButtonStates() {
		// Wait for the animation to finish before resetting the state.
		setTimeout(() => {
			displayReportConfirmationButtons = false;
			displayDeleteConfirmationButtons = false;
		}, 250);
	}

	const popupClick: PopupSettings = {
		closeQuery: 'a', // ensure that button presses do not close the popup
		event: 'click',
		placement: 'top',
		state: (e) => {
			if (e.state === false) {
				resetConfirmationButtonStates();
			}
		},
		target: 'feed-item-viewer__additional-buttons-dropdown__dropdown'
	};

	$: shouldDisplayDeleteButton = feedItemType === 'POST' && $page.data.profile?.id === authorId;
</script>

<!-- TARGET -->
<button
	name="more-options-button"
	aria-label="View more options"
	data-testid="feed-item-viewer__additional-buttons-dropdown__target"
	type="button"
	class="rounded-br-2xl rounded-tr-2xl hover:bg-surface-300 tablet:!w-24"
	use:popup={popupClick}
	use:tippy={{ content: 'More options', placement: 'bottom' }}
>
	<span>
		<iconify-icon icon="solar:menu-dots-bold" height="24" />
	</span>

	<span class="sr-only"> More options </span>
</button>

<!-- DROPDOWN -->
<PopupWrapper
	name="feed-item-viewer__additional-buttons-dropdown__dropdown"
	spacing="space-y-1"
	testid="feed-item-viewer__additional-buttons-dropdown__dropdown"
	width="w-40"
>
	<ReportButton {feedItemId} bind:displayConfirmationButtons={displayReportConfirmationButtons} />

	{#if shouldDisplayDeleteButton}
		<DeleteButton
			{feedItemId}
			bind:displayConfirmationButtons={displayDeleteConfirmationButtons}
		/>
	{/if}
</PopupWrapper>
