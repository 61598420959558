<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { dev } from '$app/environment';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { ErrorState, PopupWrapper } from '../../../elements';
	import FollowProfileButton from '../FollowProfileButton.svelte';
	import FollowPublisherButton from '../FollowPublisherButton.svelte';
	import AuthorAvatar from './AuthorAvatar.svelte';

	export let feedItemId: string;
	export let feedItemType: FeedItemType;
	export let authorName: string;
	export let authorUsername: string;
	export let authorAvatarUrl: null | string;
	export let redirectUrl: string;
	export let isOpen: boolean;

	let postCount: string = '0';
	let articleCount: string = '0';
	let followerCount: string = '0';
	let isUserFollowing: boolean = false;

	// Publisher query.
	$: query = createQuery({
		enabled: isOpen,
		onError: (error) => {
			if (dev) logger.error({ error }, 'Error fetching publisher dropdown data');
		},
		queryFn: async () => {
			if (feedItemType === 'ARTICLE') {
				return trpc($page).publisher.fetchPublisherDropdownData.query({
					publisherUsername: authorUsername
				});
			} else {
				return trpc($page).profile.fetchProfileDropdownData.query({
					username: authorUsername
				});
			}
		},
		queryKey: ['publisher-info-dropdown', authorUsername, $page.data.profile?.id]
	});

	// These need to be reactively set.
	// Otherwise, postCount, followerCount, etc. will _NOT_ be updated when the query is re-fetched,
	// and the UI will simply display "0" for these values.
	$: data = $query.data;
	$: isUserFollowing = data?.isUserFollowing ?? false;
	$: if (data && 'publisher' in data && data.publisher) {
		articleCount = data.publisher?._feed_item_count ?? '0';
		followerCount = data.publisher?._follower_count ?? '0';
	} else if (data && 'profile' in data && data.profile) {
		postCount = data.profile?._feed_item_count ?? '0';
		followerCount = data.profile?._follower_count ?? '0';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onFollowButtonResponse = (_e: CustomEvent<{ isUserFollowing: boolean }>) => {
		$query.refetch();
	};

	// For whatever reason, the native <a /> tag does not with with these links.
	// Accordingly, we must define our own handler (see below), preventDefault and stopPropation
	// (to prevent Svelte from causing a full page reload), then invoke goto(...) ourselves.
	$: handleLinkClick = (e: Event) => {
		e.preventDefault();
		e.stopPropagation();
		goto(redirectUrl);
	};

	$: handleLinkKeydown = (e: KeyboardEvent) => {
		if (!(e.key === 'Enter' || e.key === ' ')) return;
		handleLinkClick(e);
	};
</script>

<PopupWrapper
	name="feed-item__author-info-dropdown-{feedItemId}"
	border="border-2 border-surface-300/25"
	classes="space-y-4 p-4 text-surface-50"
	{isOpen}
	shadow="shadow-xl"
	testid="author-info-dropdown"
	variant=""
	width="w-72"
	zIndex=""
>
	{#if $query.isLoading}
		<!-- The actual component is 312px - 36px of padding. -->
		<div class="flex h-full min-h-[276px] w-full flex-col gap-y-4">
			<header class="space-y-2">
				<div class="skeleton h-16 w-16 rounded-token" />
				<div class="skeleton" />
				<div class="skeleton" />
			</header>

			<section class="flex gap-x-2">
				<div class="skeleton h-8 w-full" />
				<div class="skeleton h-8 w-full" />
				<div class="skeleton h-8 w-full" />
			</section>

			<footer class="space-y-2">
				<div class="skeleton h-10 w-full" />
				<div class="skeleton h-10 w-full" />
			</footer>
		</div>
	{:else if $query.isError}
		<ErrorState classes="min-h-[276px]" />
		<!-- <div class="flex h-[276px] items-center justify-center">
			<div class="text-center">
				<p class="font-bold">An error occurred.</p>
				<p class="text-sm">
					Please try again after reloading <br /> the page.
				</p>
			</div>
		</div> -->
	{:else}
		<header class="space-y-4">
			<a
				href={redirectUrl}
				class="block w-fit hover:brightness-110"
				on:click={handleLinkClick}
				on:keydown={handleLinkKeydown}
			>
				<AuthorAvatar
					borderWidth="ring-1"
					height="h-16"
					publisherAvatarUrl={authorAvatarUrl}
					rounded="rounded-token"
					testid="author-info-dropdown__author-avatar"
					width="w-16"
				/>
			</a>

			<a
				href={redirectUrl}
				class="block w-fit hover:brightness-110"
				on:click={handleLinkClick}
				on:keydown={handleLinkKeydown}
			>
				<p class="font-bold">{authorName}</p>
				<p class="opacity-50">@{authorUsername}</p>
			</a>
		</header>

		<div class="flex gap-4">
			{#if feedItemType === 'ARTICLE'}
				<p>
					<strong>{articleCount}</strong>
					<span class="opacity-50">Articles</span>
				</p>
			{:else}
				<p>
					<strong>{postCount}</strong>
					<span class="opacity-50">Posts</span>
				</p>
			{/if}
			<p>
				<strong>{followerCount}</strong>
				<span class="opacity-50">Followers</span>
			</p>
		</div>

		<footer class="space-y-2">
			{#if feedItemType === 'ARTICLE'}
				<FollowPublisherButton
					classes="w-full"
					{isUserFollowing}
					publisherUsername={authorUsername}
					on:followButtonResponse={onFollowButtonResponse}
				/>
			{:else}
				<FollowProfileButton
					classes="w-full"
					{isUserFollowing}
					username={authorUsername}
					on:followButtonResponse={onFollowButtonResponse}
				/>
			{/if}

			<a
				href={redirectUrl}
				class="variant-ghost btn w-full"
				on:click={handleLinkClick}
				on:keydown={handleLinkKeydown}
			>
				<span>
					{#if feedItemType === 'POST'}
						Visit profile
					{:else if feedItemType === 'ARTICLE'}
						Visit publisher's feed
					{:else}
						Visit changelog
					{/if}
				</span>
			</a>
		</footer>
	{/if}
</PopupWrapper>
