<!-- 
    CONTENT
    
    @prop content - The content of the feed item.
 -->
<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { MarkdownText } from '../../../../elements';

	export let feedItemType: FeedItemType;
	export let content: null | string;
</script>

<div class="my-4 text-surface-50">
	<!-- 
	Only tl;dr is displayed for articles. We don't want to be displaying their _entire_
	content ourselves.
	-->
	{#if feedItemType !== 'ARTICLE' && content}
		<MarkdownText source={content} />
	{/if}
</div>
