import { sanitizeMarkdown } from './sanitizeMarkdown.ts';
import { wrapSecuritiesInLinks } from './wrapSecuritiesInLinks.ts';
import { wrapTagsInLinks } from './wrapTagsInLinks.ts';

type TransformMarkdownOpts = {
	transformSecurities: boolean;
	transformTags: boolean;
};

const CONFIGS = {
	COMMENT: {
		transformSecurities: true,
		transformTags: true
	},
	DEFAULT: {
		transformSecurities: false,
		transformTags: false
	},
	POST: {
		transformSecurities: true,
		transformTags: true
	}
};

/**
 * Transforms user markdown content based on the provided options.
 *
 * @param content - The markdown content to transform.
 * @param opts - The configuration to use for the transformation.
 * @parma opts.config - The configuration to use for the transformation.
 * @param opts.overrides - The options to override the default configuration.
 * @returns The transformed markdown content.
 */
export function transformUserMarkdown(
	content: string,
	{
		config,
		overrides
	}: {
		config: keyof typeof CONFIGS;
		overrides?: TransformMarkdownOpts;
	} = { config: 'DEFAULT' }
) {
	let opts = CONFIGS[config];
	opts = { ...opts, ...overrides };

	let transformed: string = content;
	if (opts?.transformTags === true) {
		transformed = wrapTagsInLinks(transformed);
	}

	if (opts?.transformSecurities === true) {
		transformed = wrapSecuritiesInLinks(transformed);
	}

	return sanitizeMarkdown(transformed);
}
