<!--
@component

	COMMENT

	List item containing a Comment. Rendered by CommentList.

	@prop comment - The comment object.
	@prop parentItemId - The parent item's ID.
	@prop parentItemType - The parent item's type.
	@prop isHead - Whether or not the comment is the head of a thread.
	@prop replyCount - The number of replies to the comment.

-->
<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import EditCommentForm from '../comment-input/EditCommentForm.svelte';
	import NewReplyForm from '../comment-input/NewReplyForm.svelte';
	import CommentContent from './Comment.Content.svelte';
	import CommentFooter from './Comment.Footer.svelte';
	import CommentHeader from './Comment.Header.svelte';

	// Props
	export let comment: FEComment;
	export let parentFeedItemId: string;
	export let isHead: boolean = false;
	export let replyCount = 0;

	// Internal/computed state
	let replyInputIsDisplayed: boolean = false;
	let editCommentFormIsDisplayed: boolean = false;

	$: isChild = !isHead;
</script>

<div class="relative w-full">
	<!-- Divider line for children... -->
	{#if isChild}
		<div class="absolute bottom-0 left-5 top-0 -my-4 -ml-px w-0.5 bg-surface-300/10" />
	{/if}

	<article
		data-id={comment.id}
		data-testid={isHead ? 'comment__head' : 'comment__reply'}
		class="flex flex-col focus:outline"
	>
		{#if comment.parent}
			<div class="my-2 text-sm" class:!pl-14={isChild}>
				<p class="line-clamp-1">
					Replying to @{comment.parent?.profile?.username}
				</p>
			</div>
		{/if}

		<CommentHeader {comment} />

		{#if editCommentFormIsDisplayed}
			<EditCommentForm
				{comment}
				{isChild}
				on:commentUpdated
				bind:isExpanded={editCommentFormIsDisplayed}
			/>
		{:else}
			<div data-testid="comment__content" class:!pl-14={isChild}>
				<!-- EDIT COMMENT FORM (displayed conditionall) -->
				{#if comment.status === 'REMOVED_BY_USER'}
					<p class="my-4 italic">This comment was removed by the author.</p>
				{:else if comment.status === 'REMOVED_AS_REPORTED'}
					<p class="my-4 italic">This comment was removed after being reported.</p>
				{:else}
					<CommentContent {comment} />

					<CommentFooter
						{comment}
						{parentFeedItemId}
						{replyCount}
						bind:editMenuIsDisplayed={editCommentFormIsDisplayed}
						bind:replyInputIsDisplayed
						on:commentDeleted
					/>
				{/if}
			</div>
		{/if}
	</article>
</div>

<!-- NEW REPLY FORM (displayed conditionally) -->
{#if !editCommentFormIsDisplayed && replyInputIsDisplayed}
	<NewReplyForm
		{isChild}
		parentCommentId={comment.id}
		{parentFeedItemId}
		bind:isExpanded={replyInputIsDisplayed}
		on:replyAdded
	/>
{/if}
