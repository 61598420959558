<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	export let type: FeedItemType;
	export let url: null | string;
</script>

{#if type === 'ARTICLE'}
	<a
		aria-label="Open Article in New Window"
		data-focusindex="1"
		href={url}
		rel="noopener noreferrer"
		target="_blank"
		class="
		variant-filled
		btn py-3
		no-underline
		decoration-transparent
		"
		on:click|stopPropagation
	>
		<!-- <IconExternalLink stroke={DEFAULT_ICON.stroke} /> -->
		<iconify-icon icon="tabler:external-link" width="20" />
		<span class="max-tablet:hidden"> Read Article </span>
	</a>
{/if}
