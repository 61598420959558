<script lang="ts">
	// Props
	export let tldr: null | string;
	export let content: null | string;

	// Internal state
	let displayFullTldr: boolean = false;

	// Set up 'Read more' toggling
	let fullContent: null | string = null;
	let slicedContent: null | string = null;
	let isNotAllDisplayed: boolean = false;

	$: fullContent = tldr ?? content;
	$: slicedContent = fullContent ? fullContent.split(' ').slice(0, 40).join(' ') : null;
	$: if (fullContent && slicedContent) {
		isNotAllDisplayed = fullContent.length > slicedContent.length;
	}

	// Reset displayFullTldr whenever content or contentSnippet changes.
	// This may look weird, but it's necessary to ensure that the values
	// stay reactive.
	$: {
		content;
		tldr;
		displayFullTldr = false;
	}
</script>

<!-- TL;DR -->
{#if tldr || content}
	<div class="my-4">
		<blockquote
			class="blockquote whitespace-pre-line !border-l-2 !border-primary-500 text-base !not-italic"
		>
			<p>
				<span class="mr-2 text-primary-500"> TLDR </span>

				<!-- 
					'mr-2' to give 'Read more' button some space. 
					Applying margin directly to button looks awk on new lines.
				-->
				<span class="mr-2">
					{#if displayFullTldr}
						{fullContent}
					{:else}
						{slicedContent}
					{/if}
				</span>

				{#if isNotAllDisplayed}
					<button
						type="button"
						on:click={() => {
							displayFullTldr = !displayFullTldr;
						}}
					>
						<span class="text-secondary-500 underline-offset-4 hover:underline">
							{#if displayFullTldr}
								Read less
							{:else}
								Read more
							{/if}
						</span>
					</button>
				{/if}
			</p>
		</blockquote>
	</div>
{/if}
