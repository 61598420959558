<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	export let name: string;
	export let classes: CssClasses = '';
	export let width: CssClasses = 'max-w-xs';
	export let shadow: CssClasses = 'shadow-xl';
	export let variant: CssClasses = '';
	export let border: CssClasses = 'ring !ring-surface-300/50';
	export let rounded: CssClasses = 'rounded-container-token';
	export let padding: CssClasses = 'p-2';
	export let spacing: CssClasses = 'space-y-2';
	export let testid: string | undefined = undefined;
	export let displayBottomArrow: boolean = false;
	export let displayTopArrow: boolean = false;
	export let isOpen: boolean | undefined = undefined;
	export let zIndex: CssClasses = 'z-[999]';
</script>

<!-- Clicks on this element must be stopped from propogating to the FeedItems below. -->
<!-- eslint-disable-next-line svelte/valid-compile -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
	aria-modal="true"
	data-is-open={isOpen}
	data-popup={name}
	data-testid={testid}
	role="dialog"
	on:mouseenter|stopPropagation
	on:mousemove|stopPropagation
	on:click|stopPropagation
	on:keypress|stopPropagation
>
	<div
		class="card {zIndex} cursor-default {padding} {spacing} {variant} {width} {shadow} {border} {rounded} {classes}"
	>
		{#if displayTopArrow}
			<div class="variant-filled-surface arrow" />
		{/if}

		<slot />

		{#if displayBottomArrow}
			<div class="variant-filled-surface arrow" />
		{/if}
	</div>
</div>
