<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	export let testid = '';
	export let classes: CssClasses = '';
</script>

<section data-testid={testid} class="flex flex-col border border-base py-4 rounded-token {classes}">
	<slot name="header" />

	{#if $$slots.header}
		<hr class="mb-2 mt-4 border-t-2 !border-surface-300/25" />
	{/if}

	<slot />

	{#if $$slots.footer}
		<hr class="mt-2 border-t-2 !border-surface-300/25" />
	{/if}

	<slot name="footer" />
</section>
