<!-- 
	@component
	
    REPORT BUTTON

	@prop feedItemId - The ID of the feed item to delete.
    @prop feedItemType - The type of feed item to delete (e.g., 'post', 'article', etc.)

-->
<script lang="ts">
	import { page } from '$app/stores';
	import { getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { clerk, constructAuthRedirect, openClerkModal } from '../../../../../../../auth';
	import ConfirmActionButtons from '../../../../../elements/buttons/ConfirmActionButtons.svelte';

	const toastStore = getToastStore();
	const modalStore = getModalStore();

	// Props...
	export let displayConfirmationButtons: boolean;
	export let feedItemId: string;

	// Set up report mutation.
	const reportMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).report.reportFeedItem.mutate({ feedItemId });
		},
		mutationKey: ['feedItem-reportClick', feedItemId],
		onError: (e) => {
			logger.warn({ error: e }, 'Error reporting feed item');

			toastStore.trigger({
				classes: 'toast-error',
				message: "We're sorry but something went wrong. Please try again."
			});
		},
		onSuccess: () => {
			modalStore.clear();
			toastStore.trigger({
				message: 'Thank you. Your report has been submitted.'
			});
		}
	});

	function submitReport() {
		if (!$reportMutation.isLoading && !($reportMutation.isError || $reportMutation.isSuccess)) {
			$reportMutation.mutate();
		}
	}

	async function openAuthModal() {
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect('/i/' + feedItemId),
			type: 'SIGN_IN'
		});
	}

	$: isAuthenticated = !!$page.data.profile?.id;
</script>

<ConfirmActionButtons
	testid="feed-item-viewer__report-confirmation-menu"
	bind:displayConfirmationButtons
	on:confirmClick|once={isAuthenticated ? submitReport : openAuthModal}
	let:openConfirmationMenu
>
	<button
		aria-label="Open report confirmation dropdown"
		data-testid="feed-item-viewer__report-button"
		disabled={$reportMutation.isLoading || $reportMutation.isError || $reportMutation.isSuccess}
		type="button"
		class="popup-menu-button"
		on:click={openConfirmationMenu}
	>
		<span>
			<iconify-icon inline icon="solar:shield-warning-linear" />
		</span>
		<span> Report </span>
	</button>
</ConfirmActionButtons>
