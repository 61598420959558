<script context="module" lang="ts">
	import { clipboard } from '@skeletonlabs/skeleton';
	const STD_MSG = 'Check out this article on Tickrr!';
	const FB_APP_ID = 1469330997191561;

	type OGSource = {
		ariaLabel: string;
		icon: string;
		link: string;
		name: string;
	};
</script>

<script lang="ts">
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { generateShareableLink } from '@tickrr/lib/utils';

	import { tippy } from '../../../../../../actions';
	import { getStores } from '../../../../../../stores';
	import SectionWrapper from './SectionWrapper.svelte';

	const toastStore = getToastStore();
	const { isExtensionStore } = getStores(['isExtensionStore']);

	// Constants
	const ICON_HEIGHT = '1.5rem';
	const BUTTON_CLASSES =
		'btn w-full items-center justify-start rounded-none gap-x-3 !py-3 text-sm hover:variant-filled-surface';

	// Props...
	export let itemId: string;
	export let title: string;

	// Helpers...
	const handleCopyButtonClick = () => {
		toastStore.trigger({
			message: 'Link copied to clipboard!'
		});
	};

	// State...
	$: shareableLink = generateShareableLink({ isExtension: $isExtensionStore, itemId });

	const encodedPrologue = encodeURIComponent(STD_MSG);
	$: encodedTitle = encodeURIComponent(`${title} | Tickrr.io`);
	$: encodedUrl = encodeURIComponent(shareableLink);

	let ogSources: OGSource[] = [];
	$: ogSources = [
		{
			ariaLabel: 'Share on Twitter',
			// icon: 'logos:twitter',
			icon: 'ri:twitter-x-line',
			link: `https://twitter.com/intent/tweet?text=${encodedPrologue}&url=${encodedUrl}&hashtags=tickrr`,
			name: 'Twitter'
		},
		{
			ariaLabel: 'Share on Reddit',
			icon: 'logos:reddit-icon',
			link: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
			name: 'Reddit'
		},
		{
			ariaLabel: 'Share on Facebook',
			icon: 'logos:facebook',
			link: `https://www.facebook.com/dialog/feed?
			app_id=${FB_APP_ID}
			&display=popup
			&link=${encodedUrl}
			&redirect_uri=${encodedUrl}
			`,
			name: 'Facebook'
		},
		{
			ariaLabel: 'Share on LinkedIn',
			icon: 'devicon:linkedin',
			link: `
			http://www.linkedin.com/shareArticle?
			mini=true
			&url=${encodedUrl}
			&title=${encodedPrologue}
			&summary=${encodedTitle}
			`,
			name: 'LinkedIn'
		},
		{
			ariaLabel: 'Share via Email',
			icon: 'fluent:mail-24-regular',
			link: `mailto:?subject=${encodedTitle}&body=${encodedPrologue + ' ' + encodedUrl}`,
			name: 'Email'
		}
	];
</script>

<SectionWrapper testid="side-panel__share-article-buttons">
	<h3 class="sr-only">Social links</h3>
	<ul class="flex w-full flex-col gap-1">
		<!-- Third Party Share Buttons -->
		{#each ogSources as ogSource (ogSource.name)}
			<li>
				<a
					aria-label={ogSource.ariaLabel}
					href={ogSource.link}
					rel="noopener noreferrer"
					target="_blank"
					class={BUTTON_CLASSES}
					use:tippy={{ content: ogSource.ariaLabel }}
				>
					<iconify-icon inline icon={ogSource.icon} height={ICON_HEIGHT} />
					<span class="truncate">{ogSource.name}</span>
				</a>
			</li>
		{/each}

		<!-- Copy Link Button -->
		<li>
			<button
				aria-label="Copy link to clipboard"
				type="button"
				class={BUTTON_CLASSES}
				on:click={handleCopyButtonClick}
				use:clipboard={shareableLink}
				use:tippy={{ content: 'Copy link to clipboard' }}
			>
				<iconify-icon inline icon="solar:copy-linear" height={ICON_HEIGHT} />
				<span class="truncate">Copy link</span>
			</button>
		</li>
	</ul>
</SectionWrapper>
