<!-- 
	@component
	
    REPORT BUTTON

	@prop feedItemId - The ID of the feed item to delete.
    @prop feedItemType - The type of feed item to delete (e.g., 'post', 'article', etc.)

-->
<script lang="ts">
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation, useQueryClient } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { clerk, constructAuthRedirect, openClerkModal } from '../../../../../../../auth';
	import { ConfirmActionButtons } from '../../../../../elements';

	const toastStore = getToastStore();
	const queryClient = useQueryClient();

	// Props...
	export let parentFeedItemId: string;
	export let commentId: string;
	export let displayConfirmationButtons: boolean;

	// Set up report mutation.
	const reportMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).report.reportComment.mutate({
				commentId
			});
		},
		mutationKey: ['comment-reportClick', commentId],
		onError: (e) => {
			logger.error({ error: e }, 'Error reporting comment');

			toastStore.trigger({
				classes: 'toast-error',
				message: "We're sorry but something went wrong. Please try again."
			});
		},
		onSuccess: () => {
			// Show toast.
			toastStore.trigger({
				message: 'Thank you. Your report has been submitted.'
			});
			// Invalidate comments query.
			queryClient.invalidateQueries(['comments', parentFeedItemId]);
		}
	});

	function submitReport() {
		if (!$reportMutation.isLoading && !($reportMutation.isError || $reportMutation.isSuccess)) {
			$reportMutation.mutate();
		}
	}

	async function redirectToAuthPage() {
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect('/i/' + parentFeedItemId),
			type: 'SIGN_IN'
		});
	}

	$: isAuthenticated = !!$page.data.profile?.id;
</script>

<ConfirmActionButtons
	testid="comment__report-confirmation-menu"
	bind:displayConfirmationButtons
	on:confirmClick|once={isAuthenticated ? submitReport : redirectToAuthPage}
	let:openConfirmationMenu
>
	<button
		aria-label="Open report confirmation dropdown"
		data-testid="comment__report-button"
		disabled={$reportMutation.isLoading || $reportMutation.isError || $reportMutation.isSuccess}
		type="button"
		class="popup-menu-button"
		on:click|stopPropagation={openConfirmationMenu}
	>
		<span>
			<iconify-icon inline icon="solar:shield-warning-linear" />
		</span>
		<span> Report </span>
	</button>
</ConfirmActionButtons>
