<!-- 
	@component

	Converts relative image URLs to absolute URLs if the app is running as an extension.
	
	@prop {string} src
	@prop {string} alt
	@requires env.VITE_WEBAPP_URL
 -->
<script lang="ts">
	import { getIsExtensionStore } from '../../../../stores';

	const isExtensionStore = getIsExtensionStore();

	export let src: string;
	export let alt: string;

	$: modifiedSrc = $isExtensionStore ? `${import.meta.env.VITE_WEBAPP_URL}${src}` : src;
</script>

<img {alt} src={modifiedSrc} {...$$restProps} />
