<script lang="ts">
	import { page } from '$app/stores';
	import { type PopupSettings, popup } from '@skeletonlabs/skeleton';

	import { tippy } from '../../../../../../../actions';
	import { PopupWrapper } from '../../../../../elements';
	import DeleteButton from './Comment.DeleteButton.svelte';
	import EditButton from './Comment.EditButton.svelte';
	import { COMMENT_FOOTER_BUTTON_CLASSES } from './Comment.Footer.svelte';
	import ReportButton from './Comment.ReportButton.svelte';

	// Props...
	export let commentId: string;
	export let parentFeedItemId: string;
	export let authorId: null | string;
	export let editMenuIsDisplayed: boolean;

	// State...
	let displayReportConfirmationButtons: boolean = false;
	let displayDeleteConfirmationButtons: boolean = false;

	function resetConfirmationButtonStates() {
		// Wait for the animation to finish before resetting the state.
		setTimeout(() => {
			displayReportConfirmationButtons = false;
			displayDeleteConfirmationButtons = false;
		}, 250);
	}

	const popupClick: PopupSettings = {
		closeQuery: 'a', // ensure that button presses do not close the popup
		event: 'click',
		placement: 'top',
		state: (e) => {
			if (e.state === false) {
				resetConfirmationButtonStates();
			}
		},
		target: `comment__additional-buttons-dropdown__dropdown-${commentId}`
	};

	$: isAuthor = $page.data.profile?.id === authorId;
	$: shouldDisplayEditButton = isAuthor;
	$: shouldDisplayDeleteButton = isAuthor;
</script>

<!-- TARGET -->
<button
	aria-label="View additional options"
	data-testid="comment__additional-buttons-dropdown__target"
	type="button"
	class="{COMMENT_FOOTER_BUTTON_CLASSES} hover:variant-soft-surface"
	use:popup={popupClick}
	use:tippy={{ content: 'More options', placement: 'bottom' }}
>
	<span>
		<iconify-icon inline icon="solar:menu-dots-bold" height="1.125em" />
	</span>

	<span class="sr-only"> More options </span>
</button>

<!-- DROPDOWN -->
<PopupWrapper
	name="comment__additional-buttons-dropdown__dropdown-{commentId}"
	spacing="space-y-1"
	testid="comment__additional-buttons-dropdown__dropdown"
	width="w-40"
>
	{#if shouldDisplayEditButton}
		<EditButton bind:editMenuIsDisplayed />
	{/if}

	<ReportButton
		{commentId}
		{parentFeedItemId}
		bind:displayConfirmationButtons={displayReportConfirmationButtons}
	/>

	{#if shouldDisplayDeleteButton}
		<DeleteButton
			{commentId}
			bind:displayConfirmationButtons={displayDeleteConfirmationButtons}
			on:commentDeleted
		/>
	{/if}
</PopupWrapper>
