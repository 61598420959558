<!-- 
	@component
	
	@desc Follow profile button component
	@desc This component is used to follow a profile

	@prop username - The username of the profile to follow
	@prop isUserFollowing - Whether the user is following the profile or not
	@prop classes - Any additional classes to add to the button
	
-->
<script lang="ts">
	import { dev } from '$app/environment';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { createEventDispatcher } from 'svelte';

	import { tippy } from '../../../../actions';

	const dispatch = createEventDispatcher<{
		followButtonResponse: { isUserFollowing: boolean };
	}>();

	const toastStore = getToastStore();

	export let username: string;
	export let isUserFollowing: boolean = false;
	export let classes: string = '';

	const followMutation = createMutation({
		mutationFn: async ({ isFollowing }: { isFollowing: boolean }) => {
			if (username === $page.data.profile?.username)
				throw new Error('Cannot follow yourself');

			if (isFollowing) {
				const data = await trpc($page).profile.unfollow.mutate({
					username
				});
				if (!data.success) throw new Error('Failed to unfollow profile.');
				return data;
			}

			const data = await trpc($page).profile.follow.mutate({
				username
			});
			if (!data.success) throw new Error('Failed to follow profile.');
			return data;
		},
		mutationKey: ['follow-profile', username],
		onError: (error) => {
			if (dev) logger.error({ error }, 'Error following profile');
			triggerErrorToast();
		},
		onSuccess: (data) => {
			dispatch('followButtonResponse', { isUserFollowing: data.isFollowing });
		}
	});

	const handleUnauthorizedUser = () => {
		// Trim the leading slash.
		const currentLocation = $page.url.pathname.slice(1);
		goto(`/auth?redirectTo=${currentLocation}`);
		return;
	};

	const handleFollowButtonClick = ({ isFollowing }: { isFollowing: boolean }) => {
		if (!$page.data.profile?.id) {
			handleUnauthorizedUser();
			return;
		}

		if ($followMutation.isLoading) return;

		$followMutation.mutate({ isFollowing });
	};

	const triggerErrorToast = () => {
		toastStore.trigger({
			classes: 'toast-error',
			message: 'An error occurred on our end. Please refresh the page and try again.'
		});
	};

	$: isUserSameUser = username === $page.data.profile?.username;
</script>

<button
	aria-label="Follow user"
	aria-pressed={isUserFollowing}
	data-is-following={isUserFollowing}
	data-testid="follow-button"
	disabled={$followMutation.isLoading || isUserSameUser}
	type="button"
	class="btn {classes}"
	class:!variant-filled-error={isUserFollowing}
	class:variant-filled={!isUserFollowing}
	on:click|stopPropagation
	on:click={() => handleFollowButtonClick({ isFollowing: isUserFollowing })}
	use:tippy={{
		content: isUserSameUser
			? 'You cannot follow yourself'
			: isUserFollowing
				? 'Unfollow'
				: 'Follow'
	}}
>
	<span> {isUserFollowing ? 'Unfollow' : 'Follow'} </span>
</button>
