<script lang="ts">
	import SectionWrapper from './SectionWrapper.svelte';

	export let publishedAt: Date | null;

	// For converting stringifed dates stored in Redis.
	$: if (publishedAt) publishedAt = new Date(publishedAt);
</script>

<SectionWrapper classes="items-center gap-2" testid="side-panel__date-published">
	<iconify-icon icon="solar:calendar-outline" width="32" />
	<!-- <iconify-icon icon="solar:calendar-linear" height="2em" width="auto" /> -->
	<!-- <iconify-icon icon="fluent:calendar-24-regular" height="2em" width="auto" /> -->

	{publishedAt?.toLocaleDateString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric'
	})}
</SectionWrapper>
