<script context="module" lang="ts">
</script>

<script lang="ts">
	import type { IGif } from '@giphy/js-types';

	import { renderGrid } from '@giphy/js-components';
	import { logger } from '@tickrr/lib/logger';
	import _ from 'lodash';
	import { onDestroy, onMount } from 'svelte';

	import { getStores } from '../../../../stores';
	import { GIF_LOAD_LIMIT, GUTTER, LAYOUT, gf } from './Common.svelte';

	const { isMobileStore } = getStores(['isMobileStore']);

	export let handleGifClick: (gif: IGif, e: Event) => void;
	export let wrapperClasses: string = '';

	let el: HTMLElement | null = null;
	let wrapperEl: HTMLElement | null = null;
	let grid: { remove: () => void } = { remove: () => {} };

	// Create a fetch gifs function that takes an offset
	// this will allow the grid to paginate as the user scrolls.
	const fetchGifs = async (offset: number) => {
		return await gf.trending({ limit: GIF_LOAD_LIMIT, offset });
	};

	// Creating a grid with window resizing and remove-ability
	const makeGrid = ({
		isMobile,
		onGifClick,
		targetEl
	}: {
		isMobile: boolean;
		onGifClick: (gif: IGif, e: Event) => void;
		targetEl: HTMLElement;
	}) => {
		const render = () => {
			return renderGrid(
				{
					columns: isMobile ? LAYOUT.mobile.columns : LAYOUT.tablet.columns,
					fetchGifs,
					gutter: GUTTER,
					onGifClick: (gif: IGif, e: Event) => {
						e.preventDefault();
						onGifClick(gif, e);
					},
					width: isMobile ? LAYOUT.mobile.width : LAYOUT.tablet.width
				},
				targetEl
			);
		};
		const resizeRender = _.throttle(render, 500);
		window.addEventListener('resize', resizeRender, false);
		const remove = render();
		return {
			remove: () => {
				remove();
				window.removeEventListener('resize', resizeRender, false);
			}
		};
	};

	onMount(() => {
		if (!el) {
			logger.error({ el }, 'GiphyGrid: No element found!');
			return;
		}

		grid = makeGrid({ isMobile: $isMobileStore, onGifClick: handleGifClick, targetEl: el });
	});

	onDestroy(() => {
		grid.remove();
	});
</script>

<div bind:this={wrapperEl} data-testid="giphy-grid-wrapper" class={wrapperClasses}>
	<div bind:this={el} id="giphy-grid"></div>
</div>
