import DOMPurify from 'isomorphic-dompurify';

const CONFIG = {
	allowedAttributes: ['class', 'href', 'name', 'target', 'src', 'alt'],
	allowedTags: [
		'a',
		'b',
		'blockquote',
		'br',
		'code',
		'div',
		'em',
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'hr',
		'i',
		'img',
		'kbd',
		'li',
		'ol',
		'p',
		'span',
		'strike',
		'strong',
		'sub',
		'u',
		'ul'
	]
};

/**
 * Sanitizes the given markdown string by removing any potentially unsafe HTML tags and attributes.
 *
 * @param markdown - The markdown string to sanitize.
 * @returns The sanitized markdown string.
 *
 * @see https://github.com/bevacqua/insane/blob/master/readme.markdown.
 */
export function sanitizeMarkdown(markdown: string): string {
	DOMPurify.setConfig({
		ALLOWED_ATTR: CONFIG.allowedAttributes,
		ALLOWED_TAGS: CONFIG.allowedTags
	});
	return DOMPurify.sanitize(markdown);
}
