<!-- 
    MARKDOWN IMAGE RENDERER
    @see https://github.com/pablo-abc/svelte-markdown
-->
<script lang="ts">
	export let href = '';
	export let title: string | undefined = undefined;
	export let text = '';
</script>

<img alt={text} src={href} {title} class="my-4 rounded-md" />
