<!-- 
	@component
	
	COMMENT INPUT

	This component is used to create a new comment on a feed item. It is used in the feed item viewer and is a child of the CommentInput component.	
	
	@prop feedItemId - The ID of the feed item to which this comment belongs.
	@prop parentCommentId - The ID of the comment to which this comment is being added.
	@prop newComment - The new comment draft.
	@prop testIds - The test IDs for the input.
	@prop actionBarClasses - Additional classes to apply to the action bar.
	@prop isLoading - Whether or not the form is loading.
	@prop rows - The number of rows to display in the textarea.
-->
<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { ProgressRadial, getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation, useQueryClient } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { transformUserMarkdown } from '@tickrr/lib/utils';
	import { trpc } from '@tickrr/trpc/client';
	import { createEventDispatcher } from 'svelte';

	import type { NewCommentDraft } from './NewCommentForm.svelte';

	import { tippy } from '../../../../../../../actions';
	import {
		SignedOut,
		clerk,
		constructAuthRedirect,
		openClerkModal
	} from '../../../../../../../auth';
	import { GiphySelector, SubmitButton } from '../../../../../elements';
	import MarkdownEditor from '../../../../../elements/inputs/MarkdownEditor.svelte';
	import Logo from '../../../../app/Logo.svelte';
	import GifPreview from '../comments/Comment.Gif.svelte';

	const dispatch = createEventDispatcher<{
		commentAdded: { comment: FEComment };
		commentUpdated: { newComment: FEComment };
		replyAdded: { reply: FEComment };
	}>();

	const queryClient = useQueryClient();
	const toastStore = getToastStore();
	const modalStore = getModalStore();

	// Props...
	export let type: 'COMMENT' | 'REPLY' | 'UPDATE';
	export let feedItemId: string;
	export let parentCommentId: null | string;
	export let newComment: NewCommentDraft;
	export let existingComment: FEComment | null = null;
	export let testid = 'new-comment-form';
	export let actionBarClasses: string = '';
	export let focusOnMount: boolean = false;

	export let isLoading: boolean = false;
	export let rows: number = 6;

	// Mutation...
	const newCommentMutation = createMutation({
		mutationFn: async () => {
			if (type === 'UPDATE' && !existingComment?.id) {
				throw new Error('existingCommentId is required for UPDATE type');
			}

			if (type !== 'UPDATE' && existingComment?.id) {
				throw new Error('existingCommentId is not allowed for COMMENT and REPLY types');
			}

			if (type === 'REPLY' && !parentCommentId) {
				throw new Error('parentCommentId is required for REPLY type');
			}

			if (type === 'COMMENT' && parentCommentId) {
				throw new Error('parentCommentId is not allowed for COMMENT type');
			}

			return trpc($page).comment.upsert.mutate({
				commentId: existingComment?.id ?? null,
				content: transformUserMarkdown(newComment.content, { config: 'COMMENT' }),
				giphyUrl: newComment.giphyUrl,
				parentCommentId,
				parentFeedItemId: feedItemId
			});
		},
		mutationKey: ['new-comment'],
		onError: (e) => {
			toastStore.trigger({
				classes: 'toast-error',
				message: 'Something went wrong. Please try again.'
			});

			if (dev) logger.error({ error: e }, 'Error inserting new Comment');
		},
		onSettled: () => {
			resetNewComment();
		},
		onSuccess: (data) => {
			if (dev) logger.debug({ data }, 'Successfully inserted new Comment');

			if (!$page.data.profile?.is_onboarding_completed) {
				queryClient.refetchQueries(['onboardingChecklist']);
			}

			switch (type) {
				case 'COMMENT':
					dispatch('commentAdded', { comment: data });
					break;
				case 'REPLY':
					dispatch('replyAdded', { reply: data });
					break;
				case 'UPDATE':
					dispatch('commentUpdated', { newComment: data });
					break;
				default:
					throw new Error('Invalid type');
			}
		}
	});

	// Handlers...
	function resetNewComment() {
		newComment = {
			content: '',
			giphyUrl: null
		};
	}

	function deleteGif() {
		newComment.giphyUrl = null;
	}

	async function handleUnauthorizedClick() {
		modalStore.clear();
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect('/i/' + feedItemId),
			type: 'SIGN_IN'
		});
	}

	$: isAuthorized = !!$page.data.profile?.id;
	$: isCommentValid = newComment.content.length > 0 || !!newComment.giphyUrl;
	$: handleSubmitClick = async () => {
		if (!isAuthorized) {
			await handleUnauthorizedClick();
			return;
		}

		if (!isCommentValid) {
			toastStore.trigger({
				classes: 'toast-error',
				message:
					'Comments must either be: (1) at least 1 character long, or (2) contain a GIF.'
			});
			return;
		}

		$newCommentMutation.mutate();
	};
</script>

<form
	id="comment-form"
	action="#"
	data-testid={testid}
	class="text-surface-800-100-token"
	on:submit|preventDefault
>
	<!-- "JOIN NOW" BANNER -->
	<SignedOut>
		<div class="variant-filled flex w-full items-center justify-between gap-x-6 p-2 tablet:p-4">
			<p class="flex items-center gap-x-3">
				<Logo
					wrapperProps={{ classes: 'max-tablet:hidden', height: 'h-8', width: 'w-10' }}
				/>
				<span class="mt-1 h-fit"> You must be logged in to comment. </span>
			</p>
			<button
				type="button"
				class="variant-filled-surface btn px-6"
				on:click={handleUnauthorizedClick}
			>
				<span> Join now </span>
			</button>
		</div>
	</SignedOut>

	<!-- EDITOR -->
	<MarkdownEditor
		{actionBarClasses}
		borderColor="border-transparent"
		{focusOnMount}
		{rows}
		on:keydown
		on:keypress
		on:keyup
		bind:content={newComment.content}
	>
		<svelte:fragment slot="additional-draft-panel-content">
			{#if newComment.giphyUrl}
				<div class="relative w-fit pl-4">
					<button
						aria-label="Remove GIF"
						type="button"
						class="btn-icon btn-icon-sm absolute right-2 top-2 z-10 hover:variant-glass"
						on:click={deleteGif}
						use:tippy={{ content: 'Remove GIF', placement: 'top' }}
					>
						<iconify-icon icon="tabler:x"></iconify-icon>
						<span class="sr-only">Remove GIF</span>
					</button>
					<GifPreview gifUrl={newComment.giphyUrl} />
				</div>
			{/if}
		</svelte:fragment>

		<svelte:fragment slot="additional-preview-panel-content">
			{#if newComment.giphyUrl}
				<div class="pl-4">
					<GifPreview gifUrl={newComment.giphyUrl} />
				</div>
			{/if}
		</svelte:fragment>

		<svelte:fragment slot="pre-action-bar">
			<slot name="pre-action-bar"><!-- optional fallback --></slot>
		</svelte:fragment>

		<svelte:fragment slot="actions-lead">
			<GiphySelector bind:selectedGifUrl={newComment.giphyUrl} />
		</svelte:fragment>

		<svelte:fragment slot="actions-trail">
			<SubmitButton
				arialabel="Submit new comment"
				disabled={!isCommentValid}
				redirectUrl={`/i/${feedItemId}`}
				bind:isLoading
				on:click={handleSubmitClick}
			>
				{#if isLoading}
					<ProgressRadial class="h-8 w-8" />
				{:else}
					<span>Submit</span>
				{/if}
			</SubmitButton>
		</svelte:fragment>
	</MarkdownEditor>
</form>
