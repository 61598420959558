<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { generateLinkToVisitAuthorsPage } from '@tickrr/lib/utils';

	import AuthorAvatar from '../../../feed/_internal/AuthorAvatar.svelte';
	import PublishedAtDate from '../../../feed/_internal/PublishedAtDate.svelte';

	// Props...
	export let feedItemType: FeedItemType;
	export let publisherUsername: string;
	export let publisherName: string;
	export let publisherAvatarUrl: null | string;
	export let publishedAt: Date;
	export let classes: CssClasses = '';

	$: redirectUrl = generateLinkToVisitAuthorsPage({
		authorUsername: publisherUsername,
		feedItemType
	});
</script>

<!-- 
	@component

	FEED ITEM HEADER

	Displays the header of a feed item.

	@prop author - The author of the feed item.
	@prop avatarUrl - The avatar url of the feed item's publisher.
	@prop publishedAt - The date the feed item was published.
	@prop classes - Any additional classes to add to the component.
-->
<header class="flex w-full items-center justify-between {classes}">
	<!--  Avatar -->
	<a href={redirectUrl} class="flex items-center gap-x-2">
		<AuthorAvatar {publisherAvatarUrl} />

		<!-- Author Name -->
		<span data-testid="feed-item__author-name" class="truncate">
			{publisherName}
		</span>
	</a>

	<!-- Pub Date -->
	<PublishedAtDate {publishedAt} />
</header>
