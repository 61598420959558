<script lang="ts">
	import type { IGif } from '@giphy/js-types';

	import { type PopupSettings, popup } from '@skeletonlabs/skeleton';
	import { logger } from '@tickrr/lib/logger';
	import _ from 'lodash';

	import { ACTION_ICON_CLASSES } from '../inputs/MarkdownEditor.svelte';
	import { tippy } from './../../../../actions/tippy.ts';
	import { QUERY_THROTTLE } from './Common.svelte';
	import GifPreview from './Giphy.GIFPreview.svelte';
	import GiphyIcon from './Giphy.Icon.svelte';
	import GiphySearchBar from './Giphy.SearchBar.svelte';
	import SearchGrid from './Giphy.SearchGrid.svelte';
	import TrendingGrid from './Giphy.TrendingGrid.svelte';

	export let selectedGifUrl: null | string = null;

	let previewedGif: IGif | null = null;
	let query: string = '';
	let throttledQuery = '';
	let isComponentOpen = false;

	const popupClick: PopupSettings = {
		closeQuery: '#select-giphy-button',
		event: 'click',
		placement: 'top',
		state: (e) => {
			isComponentOpen = e.state === true;
		},
		target: 'giphy-selector'
	};

	function handleGridGifClick(gif: IGif, e: Event) {
		logger.debug({ e, gif }, 'GiphyGrid: Gif clicked!');
		previewedGif = gif;
	}

	function handleSelectClick(gif: IGif | null) {
		selectedGifUrl = gif?.images.fixed_width.url || null;
	}

	const setThrottledQuery = _.throttle((value: string) => {
		throttledQuery = value;
	}, QUERY_THROTTLE);

	function backToGrid() {
		previewedGif = null;
	}

	$: setThrottledQuery(query);
</script>

<button
	data-testid="select-giphy-button"
	type="button"
	class={ACTION_ICON_CLASSES}
	use:popup={popupClick}
	use:tippy={{ content: 'Add GIPHY gif', placement: 'top' }}
>
	<span class="scale-50">
		<GiphyIcon />
	</span>
	<span class="sr-only">Add GIPHY gif</span>
</button>

<div
	aria-hidden={!isComponentOpen}
	data-popup="giphy-selector"
	data-testid="giphy-selector"
	class="card z-[60] w-full max-w-xs overflow-x-hidden overflow-y-scroll border border-base bg-component"
>
	<GiphySearchBar bind:value={query} />
	{#if previewedGif}
		<GifPreview
			selectedGif={previewedGif}
			on:backToGridClick={backToGrid}
			on:gifSelected={(e) => handleSelectClick(e.detail.gif)}
		/>
	{:else if query.length > 0}
		{#key throttledQuery}
			<SearchGrid
				handleGifClick={handleGridGifClick}
				wrapperClasses="h-96"
				bind:searchValue={throttledQuery}
			/>
		{/key}
	{:else}
		<TrendingGrid handleGifClick={handleGridGifClick} wrapperClasses="h-96" />
	{/if}
</div>
