<script lang="ts">
	import { dev } from '$app/environment';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation, useQueryClient } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { createEventDispatcher } from 'svelte';

	import { tippy } from '../../../../actions';

	const dispatch = createEventDispatcher<{
		followButtonResponse: { isUserFollowing: boolean };
	}>();

	const queryClient = useQueryClient();
	const toastStore = getToastStore();

	export let publisherUsername: string;
	export let isUserFollowing: boolean = false;
	export let classes: string = '';

	async function refetchOnboardingData() {
		// We only need to refetch the onboarding checklist if the user has not completed it.
		if (!$page.data.profile?.is_onboarding_completed) {
			await queryClient.refetchQueries(['onboardingChecklist']);
		}
	}

	const followMutation = createMutation({
		mutationFn: async ({ isFollowing }: { isFollowing: boolean }) => {
			if (isFollowing) {
				const data = await trpc($page).publisher.unfollow.mutate({
					publisherUsername
				});
				if (!data.success) throw new Error('Failed to unfollow publisher.');
				return data;
			}

			const data = await trpc($page).publisher.follow.mutate({
				publisherUsername
			});
			if (!data.success) throw new Error('Failed to follow publisher.');
			return data;
		},
		mutationKey: ['follow-publisher', publisherUsername],
		onError: (error) => {
			if (dev) logger.error({ error }, 'Error following publisher');
			triggerErrorToast();
		},
		onSuccess: async (data) => {
			dispatch('followButtonResponse', { isUserFollowing: data.isFollowing });
			await refetchOnboardingData();
		}
	});

	const handleUnauthorizedUser = () => {
		const currentLocation = $page.url.pathname.slice(1);
		goto(`/auth?redirectTo=${currentLocation}`);
		return;
	};

	const handleFollowButtonClick = ({ isFollowing }: { isFollowing: boolean }) => {
		if (!$page.data.profile?.id) {
			handleUnauthorizedUser();
			return;
		}

		if ($followMutation.isLoading) return;

		$followMutation.mutate({ isFollowing });
	};

	const triggerErrorToast = () => {
		toastStore.trigger({
			classes: 'toast-error',
			message: 'An error occurred on our end. Please refresh the page and try again.'
		});
	};
</script>

<button
	aria-label="Follow publisher"
	aria-pressed={isUserFollowing}
	data-is-following={isUserFollowing}
	data-testid="follow-button"
	disabled={$followMutation.isLoading}
	type="button"
	class="variant-filled btn {classes}"
	class:!variant-soft-error={isUserFollowing}
	on:click|stopPropagation
	on:click={() => handleFollowButtonClick({ isFollowing: isUserFollowing })}
	use:tippy={{
		content: isUserFollowing ? 'Unfollow' : 'Follow'
	}}
>
	<span> {isUserFollowing ? 'Unfollow' : 'Follow'} </span>
</button>
