<!-- 
	@component
	
	COMMENT - Reply Button

	@prop replyInputIsDisplayed - Whether or not the reply input is displayed.
 -->
<script lang="ts">
	import { tick } from 'svelte';

	import { tippy } from '../../../../../../../actions';
	import { COMMENT_FOOTER_BUTTON_CLASSES } from './Comment.Footer.svelte';

	export let replyInputIsDisplayed: boolean;

	function focusReplyInput() {
		const replyDropdown = document.querySelector(
			'#new-reply-input'
		) as HTMLTextAreaElement | null;
		if (replyDropdown) {
			replyDropdown.focus();
		}
	}

	// Define helpers.
	function toggleReplyDropdown() {
		replyInputIsDisplayed = !replyInputIsDisplayed;
		tick(); // Force re-render to ensure element is present before focusing.
		setTimeout(() => {
			// Await transition then focus input.
			focusReplyInput();
		}, 500);
	}
</script>

<button
	aria-label="Display input to reply to comment"
	data-testid="comment__reply-button"
	type="button"
	class="
	{COMMENT_FOOTER_BUTTON_CLASSES}
    hover:variant-soft-secondary
	"
	on:click|stopPropagation|preventDefault={toggleReplyDropdown}
	use:tippy={{ content: 'Reply to this comment' }}
>
	<iconify-icon inline icon="solar:chat-line-outline" height="1.125em" />

	<span class=""> &nbsp; Reply</span>
</button>
