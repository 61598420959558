<!-- 
    @component
    
    STICKY ARTICLE INFO
    
    @prop itemType 
    @prop publisherName
    @prop title
    @prop classes

-->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';
	import type { FeedItemType } from '@tickrr/lib/types';

	// Props...
	export let itemType: FeedItemType;
	export let publisherName: string;
	export let title: string;
	export let classes: CssClasses = '';
</script>

<div class="hidden w-full overflow-hidden tablet:block {classes}">
	{#if itemType === 'ARTICLE'}
		<p class="line-clamp-1">
			Published by {publisherName}
		</p>
	{:else}
		<p class="line-clamp-1">
			Posted by @{publisherName}
		</p>
	{/if}

	<p class="text-surface-900-50-token line-clamp-1 font-bold">
		{title}
	</p>
</div>
