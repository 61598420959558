<!--
	@component

	SHARE BUTTON

	Single button, displaying a common-looking 'share' icon. 
	Used, for example, in the footer of Posts and Articles to allow users to share 
	the post to Facebook, Twitter, etc. 

	@prop itemType - The type of item being shared (e.g., 'post', 'article', etc.)
	@prop itemId - The ID of the item being shared
	@prop title - The title of the item being shared

-->

<script lang="ts">
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { useQueryClient } from '@tanstack/svelte-query';
	import { generateShareableLink } from '@tickrr/lib/utils';

	import { clipboard, tippy, webshare } from '../../../../../actions';
	import { getStores } from '../../../../../stores';
	import { FOOTER_BUTTON_CLASSES } from '../FeedItem.svelte';

	const queryClient = useQueryClient();
	const toastStore = getToastStore();
	const { isExtensionStore, isWebshareSupportedStore } = getStores([
		'isExtensionStore',
		'isWebshareSupportedStore'
	]);

	// Props...
	export let itemId: string;
	// eslint-disable-next-line svelte/valid-compile
	// export let title: string; // Not being used currently, but may be needed for webshare in future.

	// State...
	let linkHasBeenCopied = false;

	async function countShareInOnboardingChecklist() {
		if (!$page.data.profile?.is_onboarding_completed) {
			await queryClient.refetchQueries(['onboardingChecklist']);
		}
	}

	// Helpers...
	async function handleClick() {
		// We only need to track the click if a user is on a device that supports Web Share;
		// the WebShare API will handle the rest.
		if ($isWebshareSupportedStore) {
			await countShareInOnboardingChecklist();
			return;
		}

		linkHasBeenCopied = true;

		toastStore.trigger({
			message: 'Link copied to clipboard!'
		});

		await countShareInOnboardingChecklist();

		setTimeout(() => {
			linkHasBeenCopied = false;
		}, 2000);
	}

	// Derived state...
	$: shareableLink = generateShareableLink({ isExtension: $isExtensionStore, itemId });
</script>

<button
	name="share-button"
	data-testid="feed-item__share-button"
	disabled={linkHasBeenCopied}
	type="button"
	class="
	{FOOTER_BUTTON_CLASSES}
	hover:variant-soft-secondary
	hover:!text-secondary-500
	"
	on:click|stopPropagation={handleClick}
	use:clipboard={{ content: shareableLink, enabled: !$isWebshareSupportedStore }}
	use:tippy={{ content: 'Share' }}
	use:webshare={{ enabled: $isWebshareSupportedStore, shareData: { url: shareableLink } }}
>
	{#if linkHasBeenCopied}
		<span class="mr-2 hidden text-sm tablet:inline">Copied</span>
		<iconify-icon inline icon="solar:check-read-line-duotone" />
	{:else}
		<span class="sr-only">Share</span>
		<iconify-icon icon="solar:multiple-forward-right-outline" />
	{/if}
</button>
