<!-- 
    @component 

    COMMENT WITH CHILDREN
    
    List item containing a Comment with children (replies). Rendered by CommentList.
	Calls itself recursively to render children.

    @prop comment - The comment object.
	@prop groupedComments - The comment's children (replies) grouped by parent ID.
    @prop parentItemId - The parent item's ID.
    @prop parentItemType - The parent item's type.
	@prop directDescendants - The comment's direct children (replies).
	
-->
<script lang="ts">
	import type { FEComment } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { TreeViewItem } from '@skeletonlabs/skeleton';
	import { getDescendantCount } from '@tickrr/lib/utils';

	import Comment from './Comment.svelte';

	// Props
	export let comment: FEComment;
	export let groupedComments: Record<string, FEComment[]>;
	export let parentFeedItemId: string;
	export let parentFeedItemType: FeedItemType;
	export let directDescendants: FEComment[];

	export let isHead: boolean = false;

	let isExpanded = true;

	// Forces body to expand to fill entire width of parent. This should be enabled by default
	// eventually, but it is not as of skeleton@1.11.0.
	const baseClasses = '[&>.tree-item-content]:w-full';

	$: headClasses = `${baseClasses} ${isExpanded ? '!ring-1 ring-base' : ''}`;

	const childClasses = `${baseClasses} max-tablet:pl-2`;

	const headWrapperClasses = `border border-base rounded-container-token`;

	$: replyCount = getDescendantCount<FEComment>(comment, groupedComments);
</script>

<div class={isHead ? headWrapperClasses : ''}>
	<TreeViewItem
		indent="ml-0"
		regionSummary={isHead ? headClasses : childClasses}
		regionSymbol="hidden"
		spacing="space-x-0"
		bind:open={isExpanded}
	>
		<Comment
			{comment}
			{isHead}
			{parentFeedItemId}
			{replyCount}
			on:commentUpdated
			on:replyAdded
			on:commentDeleted
		/>

		<svelte:fragment slot="children">
			{#each directDescendants as descendant (descendant.id)}
				<svelte:self
					comment={descendant}
					{descendant}
					directDescendants={groupedComments[descendant.id] ?? []}
					{groupedComments}
					isHead={false}
					{parentFeedItemId}
					{parentFeedItemType}
					on:commentUpdated
					on:replyAdded
					on:commentDeleted
				/>
			{/each}
		</svelte:fragment>
	</TreeViewItem>
</div>
