<script context="module" lang="ts">
	export const NEW_COMMENT_INPUT_TRIGGER_ID = 'new-comment-input-trigger';
</script>

<script lang="ts">
	import { tick } from 'svelte';

	import UserAvatar from '../../../../../elements/images/UserAvatar.svelte';

	// Props...
	export let displayCommentInput: boolean;
	export let newCommentText: string;

	// Internal state...

	function openTextAreaIfNeededAndFocus() {
		if (!displayCommentInput) {
			displayCommentInput = true;
			tick();
		}
	}

	function forceCloseTextArea() {
		displayCommentInput = false;
	}
</script>

<button
	id={NEW_COMMENT_INPUT_TRIGGER_ID}
	aria-label={displayCommentInput ? 'Close New Comment Input' : 'Open New Comment Input'}
	data-testid="new-comment-input__trigger"
	type="button"
	class="group input-group variant-filled-surface !flex w-full items-center !rounded-2xl !border-0 px-3 py-1"
	on:click={displayCommentInput ? forceCloseTextArea : openTextAreaIfNeededAndFocus}
>
	<!-- #1: AVATAR -->
	<!-- These padding classes are needed to override styles inherited from the 'input-group' class above. -->
	<UserAvatar border="ring-2 ring-base" classes="!px-0 !py-0" height="h-10" width="w-10" />

	<!-- #2: "INPUT" -->
	<div class="w-full grow overflow-hidden !pr-0 !text-inherit">
		<!-- Text -->
		{#if !displayCommentInput}
			<p class="line-clamp-1">
				{newCommentText.length > 0 ? newCommentText : 'Share your thoughts...'}
			</p>
		{:else}
			<!-- Toggler -->
			<div class="btn mx-2 w-full !justify-center rounded-token">
				<span>
					<iconify-icon icon="tabler:chevron-up" width="20" />
				</span>
				<span> Toggle up </span>
			</div>
		{/if}

		<!-- Faux Submit Button -->
		<div class="!px-0 py-2" class:!opacity-0={displayCommentInput}>
			<div class="variant-filled btn pointer-events-none h-full rounded-lg !py-2">
				<span>Submit</span>
			</div>
		</div>
	</div>
</button>
