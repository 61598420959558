<script lang="ts">
	export let tagValue: string;
	export let classes: string = '';
	export let variant: string = 'variant-filled';
	export let padding: string = 'px-4 py-2';
	export let rounded: string = 'rounded-md';
	export let border: string = '';
	export let isInstrument: boolean = false;

	$: encodedValue = encodeURIComponent(tagValue);
</script>

<a
	href={isInstrument ? `/charts/${encodedValue}` : `/tag/${encodedValue}`}
	class="{variant} badge {border} {rounded} {padding} {classes}"
	class:!variant-filled-primary={isInstrument}
	class:uppercase={isInstrument}
	on:click
>
	{isInstrument ? '$' : '#'}{tagValue}
</a>
