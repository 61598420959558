<script lang="ts">
	import SvelteMarkdown from 'svelte-markdown';

	import Blockquote from './renderers/Blockquote.svelte';
	import Heading from './renderers/Heading.svelte';
	import Image from './renderers/Image.svelte';
	import Link from './renderers/Link.svelte';
	import List from './renderers/List.svelte';

	export let source: string;
</script>

<div data-testid="markdown-renderer" class="space-y-6">
	<SvelteMarkdown
		renderers={{
			blockquote: Blockquote,
			heading: Heading,
			image: Image,
			link: Link,
			list: List
		}}
		{source}
	/>
</div>
