<script lang="ts">
	export let feedItemId: string | undefined = undefined;
	export let classes: string = '';
	export let mainClasses: string = '';
	export let sidePanelClasses: string = '';
	export let testid: string = 'feed-item-viewer';
</script>

<!-- 
	@component
	
	ITEM VIEWER SKELETON

	Used to wrap ItemViewer.svelte and LoadingItemViewer.svelte.

-->

<div
	id={testid}
	data-id={feedItemId}
	data-testid={testid}
	class="no-scrollbar-for-mobile flex min-h-full overflow-x-hidden max-tablet:flex-col {classes}"
>
	<!-- CONTENT CONTAINER -->
	<main
		data-testid="feed-item-viewer__content"
		class="relative flex min-h-full grow flex-col p-4 tablet:overflow-hidden tablet:p-8 tablet:pt-0 {mainClasses}"
	>
		<!-- 
			We do not display loader on FeedItemPage ('/item/.../...') because it would be redundant.
			There is already a loader displayed below the Header of the AppShell.
		-->

		<slot name="main" />
	</main>

	<!-- 
		DIVIDER 
		Note: the border-color matches border-base from app.postcss 
	-->
	<span class="divider-vertical !border-surface-300/25 max-tablet:hidden" />

	<!-- 
		SIDEPANEL 
		@note mb-8 adds some space to bottom of FeedItemViewer/ItemPage on mobile. 
	-->
	<aside
		data-testid="feed-item-viewer__side-panel"
		class="
		text-surface-600-300-token
		flex
		h-full
		w-full
		shrink-0
		flex-col
		p-4
		text-sm
		tablet:max-w-[18rem]
		tablet:p-4
		tablet:pt-0
		laptop:max-w-[21rem]
		{sidePanelClasses}
		"
	>
		<slot name="side-panel" />
	</aside>
</div>
