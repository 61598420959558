<svg fill="none" height="43" viewBox="0 0 43 43" width="43" xmlns="http://www.w3.org/2000/svg">
	<rect fill="#9933FF" height="14.0941" width="2.877" x="28.7935" y="16.5286" />
	<rect fill="#00CCFF" height="2.81882" width="20.139" x="11.5315" y="30.6226" />
	<rect fill="#00FF99" height="19.7317" width="2.877" x="11.5315" y="10.8909" />
	<rect fill="#FFF35C" height="2.81882" width="11.508" x="11.5315" y="8.07208" />
	<path
		clip-rule="evenodd"
		d="M28.7935 13.7097V10.8909H25.9165V8.07208H23.0396V10.905V10.9191V13.7238V13.7238V16.5285H25.9165H28.7935V16.5285H31.6705V13.7097H28.7935Z"
		fill="#FF6666"
		fill-rule="evenodd"
	/>
	<path
		clip-rule="evenodd"
		d="M23.0396 8.07208V10.8909H20.1626L23.0396 8.07208Z"
		fill="#0F0F0F"
		fill-rule="evenodd"
		opacity="0.4"
	/>
	<path
		clip-rule="evenodd"
		d="M28.7935 19.3756V16.5286H31.6705L28.7935 19.3756Z"
		fill="#0F0F0F"
		fill-rule="evenodd"
		opacity="0.4"
	/>
</svg>
