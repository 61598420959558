<svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
	<path
		clip-rule="evenodd"
		d="M8.08412 14.2864C9.33568 15.2147 10.8782 15.7628 12.548 15.7628C16.7383 15.7628 20.1272 12.3111 20.1272 8.06279C20.1272 3.81447 16.7383 0.362793 12.548 0.362793C8.35782 0.362793 4.96886 3.81447 4.96886 8.06279C4.96886 9.85535 5.57222 11.5061 6.58454 12.8155L1.44103 18.3048C1.04452 18.728 1.06613 19.3925 1.4893 19.789C1.91246 20.1855 2.57693 20.1639 2.97343 19.7407L8.08412 14.2864ZM18.0272 8.06279C18.0272 11.1599 15.5697 13.6628 12.548 13.6628C9.52632 13.6628 7.06886 11.1599 7.06886 8.06279C7.06886 4.96573 9.52632 2.46279 12.548 2.46279C15.5697 2.46279 18.0272 4.96573 18.0272 8.06279Z"
		fill="url(#paint0_linear_0_1446)"
		fill-rule="evenodd"
	/>
	<mask
		id="mask0_0_1446"
		height="21"
		maskUnits="userSpaceOnUse"
		width="20"
		x="1"
		y="0"
		style="mask-type:luminance"
	>
		<path
			clip-rule="evenodd"
			d="M8.08412 14.2864C9.33568 15.2147 10.8782 15.7628 12.548 15.7628C16.7383 15.7628 20.1272 12.3111 20.1272 8.06279C20.1272 3.81447 16.7383 0.362793 12.548 0.362793C8.35782 0.362793 4.96886 3.81447 4.96886 8.06279C4.96886 9.85535 5.57222 11.5061 6.58454 12.8155L1.44103 18.3048C1.04452 18.728 1.06613 19.3925 1.4893 19.789C1.91246 20.1855 2.57693 20.1639 2.97343 19.7407L8.08412 14.2864ZM18.0272 8.06279C18.0272 11.1599 15.5697 13.6628 12.548 13.6628C9.52632 13.6628 7.06886 11.1599 7.06886 8.06279C7.06886 4.96573 9.52632 2.46279 12.548 2.46279C15.5697 2.46279 18.0272 4.96573 18.0272 8.06279Z"
			fill="white"
			fill-rule="evenodd"
		/>
	</mask>
	<g mask="url(#mask0_0_1446)"> </g>
	<defs>
		<linearGradient
			id="paint0_linear_0_1446"
			gradientUnits="userSpaceOnUse"
			x1="10.4075"
			x2="-9.05363"
			y1="-9.37027"
			y2="9.59486"
		>
			<stop stop-color="#FF6666" />
			<stop offset="1" stop-color="#9933FF" />
		</linearGradient>
	</defs>
</svg>
