<!-- 
    @component
    
    UPDATE COMMENT FORM

    @prop comment - The comment to be edited.
    @prop isChild - Whether or not the comment is a child of another comment.
    @prop isExpanded - Whether or not the form is expanded.
-->
<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import type { NewCommentDraft } from './NewCommentForm.svelte';

	import CommentInput from './CommentInput.svelte';

	// Props...
	export let comment: FEComment;
	export let isChild: boolean;
	export let isExpanded: boolean; // Whether *textarea* should be expanded (not entire dropdown)

	// State...
	let newComment: NewCommentDraft = {
		content: comment.content,
		giphyUrl: comment.giphy_url
	};

	function closeTextArea() {
		isExpanded = false;
	}

	// The 'toggle' event on the Skeleton.TreeViewItem is triggered on keyup.
	// Since characters are added to the textarea on keydown, we can prevent
	// 'Space' button presses from toggling the TreeViewItem using this simple function.
	function preventSpaceButtonFromOpeningOrClosingTreeView(e: KeyboardEvent) {
		if (e.key === ' ') {
			e.preventDefault();
		}
	}
</script>

<!-- INPUT GROUP -->
<div data-testid="edit-comment-form" class="mt-4 space-y-2" class:ml-8={isChild}>
	<!-- 
	`comment.feed_item_id` can be set to null in the event that a feed_item is deleted.
	Because the feed_item has been deleted, however, the user should never be able to reach this 
	view. If they do, it's a bug.
	-->
	{#if comment.feed_item_id}
		<CommentInput
			existingComment={comment}
			feedItemId={comment.feed_item_id}
			parentCommentId={comment.parent_id}
			testid="edit-comment-form"
			type="UPDATE"
			bind:newComment
			on:commentUpdated={closeTextArea}
			on:commentUpdated
			on:keyup={preventSpaceButtonFromOpeningOrClosingTreeView}
		/>
	{:else}
		<p class="text-error-500">Something went wrong.</p>
	{/if}

	<div class="flex w-full !justify-between">
		<button
			type="button"
			class="text-surface-500-400-token btn w-full !rounded-none"
			on:click={closeTextArea}
		>
			<iconify-icon icon="tabler:chevron-up" width="20" />
			<span> Close edit menu </span>
		</button>
	</div>
</div>
