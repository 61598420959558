<!-- 
    @component
    
	TRENDING TICKER LIST ITEM

	This component is used to render a single item in the trending ticker list.

	@param {FETicker} item - The ticker to display.
	@param {number} idx - The index of the ticker in the list.
-->
<script lang="ts">
	import type { FETicker } from '@tickrr/lib/types';

	// Props...
	export let item: FETicker;
	export let idx: number;

	$: name = item.nameOverride || item.name;
	$: symbol = item.symbol; // note: override does not take precedent in this component, since we display the ticker's full name.
	$: price = item.price;
	$: changeAbs = item.changeAbs;
	$: changePct = item.changePct;

	$: priceHasIncreased = changeAbs && changeAbs !== '0' && !changeAbs.startsWith('-');
	$: priceHasDecreased = !priceHasIncreased && changeAbs && changeAbs !== '0';
</script>

<li>
	<!-- 
	Note: we ALWAYS use the 'symbol' value, rather than any overriden value, 
	to ensure the proper Ticker is fetched from the DB on redirect.
-->
	<a
		href="/charts/{encodeURIComponent(item.symbol)}"
		class="
        btn
        flex
		h-full
        w-full
		gap-x-2
		whitespace-normal
        rounded-none
        px-4
		py-2
        text-left text-sm
        hover:variant-filled-surface
    "
		on:click
	>
		<!-- INDEX # -->
		<span
			class="w-11 !shrink-0 text-center text-4xl font-extrabold text-transparent"
			style="-webkit-text-stroke: 1px; -webkit-text-stroke-color: #a8b3cf;"
		>
			{idx + 1}
		</span>

		<!-- CONTENT -->
		<div class="flex flex-auto flex-col overflow-hidden">
			<!-- Symbol -->
			<div class="flex w-full justify-between font-bold text-surface-50">
				<span class="line-clamp-2 font-bold">
					{symbol}
				</span>

				<span>{price}</span>
			</div>

			<!-- Name / Change (percent + absolute) -->
			<div class="flex w-full justify-between gap-x-2 overflow-hidden">
				<span class="w-full truncate text-on-surface-token">
					{name}
				</span>

				<span
					class="w-fit !shrink-0"
					class:text-error-500={priceHasDecreased}
					class:text-success-500={priceHasIncreased}
				>
					<!-- 
						This must be done in one line (i.e., parentheses pair), not two,
						in order to ensure a space is not added between the sign (+/-) and the number.
					-->
					{(priceHasIncreased ? '+' : '') + changeAbs}
					({changePct} %)
				</span>
			</div>
		</div>
	</a>
</li>
