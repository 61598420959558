<!-- 
    @component
    
    PUBLISHED AT DATE

    Displays the date a feed item was published.

    @prop publishedAt - The date the feed item was published.

-->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { logger } from '@tickrr/lib/logger';
	import { calculateTimeSince } from '@tickrr/lib/utils';

	import { touchTippy } from '../../../../../actions';

	// Props...
	export let publishedAt: Date;
	export let classes: CssClasses = '';

	// For converting stringifed dates stored in Redis.
	$: publishedAt = new Date(publishedAt);

	let timeSince: string = '';
	let tippyContent: string = 'Posted on ?? at ??';
	$: {
		try {
			timeSince = calculateTimeSince(publishedAt);
		} catch (e) {
			logger.error({ error: e }, 'Error calculating time since');
		}
	}
	$: {
		try {
			tippyContent = `Posted on ${publishedAt.toLocaleDateString(undefined, {
				day: 'numeric',
				month: 'long',
				weekday: 'long',
				year: 'numeric'
			})} at ${publishedAt.toLocaleTimeString(undefined, {
				hour: '2-digit',
				minute: '2-digit'
			})}`;
		} catch (e) {
			logger.error({ error: e }, 'Error generating tippy content');
		}
	}
</script>

<div
	data-testid="feed-item__published-at-date"
	data-tippy-content={tippyContent}
	class="
	relative
	shrink-0 select-none
	hover:text-secondary-500
	{classes}
	"
	use:touchTippy={{ maxWidth: 250 }}
>
	<iconify-icon inline icon="solar:clock-circle-linear" />

	<span>
		{timeSince}
	</span>
</div>
