<script lang="ts">
	import ModalCloseButton from '../../modals/ModalCloseButton.svelte';

	export let isFeedItemViewerHeader: boolean;
	export let isStuck: boolean = false;
	export let classes: string = '';

	let header: HTMLElement | null;
</script>

<!-- 
	HEADER 
-->
<header
	bind:this={header}
	data-testid="sticky-header"
	class="
	relative
	flex
	h-20
    w-full
    items-center
    justify-between
    border-base
	pl-4
	rounded-tl-token
    rounded-tr-token
	{classes}
    "
	class:border-b={!isFeedItemViewerHeader}
>
	<!-- 
		title 
	-->
	<slot name="header-lead" />

	<!-- 
		mr-[1px] to adjust for the size of the border itself. 
	-->
	<div
		class="
        mr-[1px]
		flex h-full
		shrink-0 justify-end
        gap-x-2 py-4
		pr-4
        tablet:w-full tablet:max-w-[18rem]
		tablet:border-l tablet:!border-base
		laptop:max-w-[21rem]
		"
		class:!border-none={isStuck || !isFeedItemViewerHeader}
	>
		<slot name="header-trail">
			<ModalCloseButton />
		</slot>
	</div>

	<slot name="navigation-indicator" />
</header>
