import type { FEFeedItem } from '@tickrr/db';
import type { FeedItemType } from '@tickrr/lib/types';

import { FALLBACK_AVATAR } from './_internal/AuthorAvatar.svelte';

const FALLBACK_NAME = 'UNKNOWN NAME';
const FALLBACK_USERNAME = 'UNKNOWN USERNAME';

/**
 * Little helper to type narrow item type, given that
 * types cannot be cast in the markup/each block.
 * @param item - The feed item to retrieve the type from.
 * @returns The type of the feed item.
 */
export const getItemType = (item: FEFeedItem): FeedItemType => {
	return item.type as FeedItemType;
};

/**
 * Returns the name of the publisher or profile associated with the given feed item.
 * If neither a publisher nor profile is found, returns 'tickrr.io'.
 * @param item The feed item to retrieve the publisher name from.
 * @returns The name of the publisher or profile associated with the given feed item.
 */
export const getPublisherName = (item: FEFeedItem): string => {
	switch (item.type) {
		case 'DAILY_DISCUSSION':
		case 'UPDATE':
			return 'tickrr.io';
		case 'ARTICLE':
			return item.publisher?.name ?? FALLBACK_NAME;
		case 'POST':
			return item.profile?.username ?? FALLBACK_NAME;
		default:
			return FALLBACK_NAME;
	}
};

/**
 * Returns the publisher code for a given feed item.
 * @param item The feed item to retrieve the publisher code from.
 * @returns The publisher code if the item is an article, otherwise null.
 */
export const getPublisherUsername = (item: FEFeedItem): string => {
	switch (item.type) {
		case 'DAILY_DISCUSSION':
		case 'UPDATE':
			return 'team';
		case 'ARTICLE':
			return item.publisher?.username ?? FALLBACK_USERNAME;
		case 'POST':
			return item.profile?.username ?? FALLBACK_USERNAME;
		default:
			return FALLBACK_USERNAME;
	}
};

/**
 * Returns the avatar URL of the publisher or profile associated with a given feed item.
 * @param item The feed item to retrieve the avatar URL from.
 * @returns The avatar URL of the publisher or profile, or null if the
 * item does not have an associated publisher or profile.
 */
export const getPublisherAvatarUrl = (item: FEFeedItem): null | string => {
	switch (item.type) {
		case 'ARTICLE':
			return item.publisher?.avatar_url ?? null;
		case 'POST':
			return item.profile?.avatar_url ?? null;
		case 'DAILY_DISCUSSION':
		case 'UPDATE':
		default:
			return FALLBACK_AVATAR;
	}
};
