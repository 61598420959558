<!-- 
	@component
	
	DELETE BUTTON

	@prop feedItemId - The ID of the feed item to delete.
-->
<script lang="ts">
	import { page } from '$app/stores';
	import { getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { ConfirmActionButtons } from '../../../../../elements';

	const modalStore = getModalStore();
	const toastStore = getToastStore();

	// Props...
	export let feedItemId: string;
	export let displayConfirmationButtons: boolean;

	// Set up delete mutation.
	const deleteMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).post.remove.mutate(feedItemId);
		},
		mutationKey: ['feedItem-deleteClick', feedItemId],
		onError: (e) => {
			logger.warn({ error: e }, 'Error deleting feed item');

			toastStore.trigger({
				classes: 'toast-error',
				message: "We're sorry but something went wrong. Please try again."
			});
		},
		onSuccess: () => {
			// Close modal.
			modalStore.close();
			// Show toast.
			toastStore.trigger({
				message: 'Your post has been deleted. Please wait while we refresh the page.'
			});

			setTimeout(() => {
				window.location.href = '/posts';
			}, 1000);
		}
	});

	function deletePost() {
		if (!$deleteMutation.isLoading && !($deleteMutation.isError || $deleteMutation.isSuccess)) {
			$deleteMutation.mutate();
		}
	}
</script>

<ConfirmActionButtons
	testid="feed-item-viewer__delete-confirmation-buttons"
	on:confirmClick|once={deletePost}
	bind:displayConfirmationButtons
	let:openConfirmationMenu
>
	<button
		aria-label="Open delete confirmation dropdown"
		data-testid="feed-item-viewer__delete-button"
		type="button"
		class="popup-menu-button"
		on:click={openConfirmationMenu}
	>
		<span class="text-error-500">
			<iconify-icon inline icon="solar:trash-bin-trash-linear" />
		</span>
		<span class="text-error-500"> Delete </span>
	</button>
</ConfirmActionButtons>
