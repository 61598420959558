<script lang="ts">
	import { dev } from '$app/environment';
	import { invalidate } from '$app/navigation';
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { tippy } from '../../../../../../../actions';
	import { clerk, constructAuthRedirect, openClerkModal } from '../../../../../../../auth';
	import { INTERACTION_BUTTON_CLASSES } from './InteractionButtons.svelte';

	const toastStore = getToastStore();

	export let feedItemId: string;

	function triggerErrorToast(e: unknown) {
		if (dev) logger.error({ error: e }, 'Error bookmarking feed item.');
		toastStore.trigger({
			classes: 'toast-error',
			message: "We're sorry but something went wrong. Please try again."
		});
	}

	$: existingBookmark = $page.data.profile?.bookmarks.find(
		(bookmark) => bookmark.feed_item_id === feedItemId
	);

	// Set up report mutation.
	$: insertBookmarkMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).bookmark.insert.mutate({ feedItemId });
		},
		mutationKey: ['feedItem-insertBookmark', feedItemId, $page.data.profile?.id],
		onError: triggerErrorToast,
		onSuccess: (data) => {
			toastStore.trigger({
				message: 'The item has been bookmarked!'
			});

			if (!$page.data.profile) {
				logger.warn(
					{
						profile: $page.data.profile
					},
					'No profile data found in store. Cannot add new bookmark to local state.'
				);
				return;
			}

			invalidate('profile::data');

			existingBookmark = data;
		}
	});

	$: removeBookmarkMutation = createMutation({
		mutationFn: async () => {
			if (!existingBookmark?.feed_item_id) return;
			return trpc($page).bookmark.delete.mutate({
				feedItemId: existingBookmark.feed_item_id
			});
		},
		mutationKey: ['feedItem-insertBookmark', feedItemId, $page.data.profile?.id],
		onError: triggerErrorToast,
		onSuccess: () => {
			toastStore.trigger({
				message: 'The item has been removed from your bookmarks.'
			});

			if (!$page.data.profile) {
				logger.warn(
					{
						existingBookmark,
						profile: $page.data.profile
					},
					'No profile data found in store. Cannot remove bookmark from local state.'
				);
				return;
			}

			invalidate('profile::data');

			// Note: this must come after the filter above, otherwise the bookmark will still be in the array.
			existingBookmark = undefined;
		}
	});

	async function handleBookmarkButtonClick() {
		if (!$page.data.profile?.id) {
			await openClerkModal({
				clerk: $clerk,
				redirectUrl: constructAuthRedirect('/i/' + feedItemId),
				type: 'SIGN_IN'
			});
			return;
		}

		const isAMutLoading =
			$insertBookmarkMutation.isLoading || $removeBookmarkMutation.isLoading;

		if (isAMutLoading) {
			toastStore.trigger({
				classes: 'toast',
				message: 'Please wait until the current action is completed.'
			});
			return;
		}

		if (existingBookmark) {
			$removeBookmarkMutation.mutate();
		} else {
			$insertBookmarkMutation.mutate();
		}
	}
</script>

<!-- 
	@component
	
	BOOKMARK BUTTON
 -->
<button
	name="bookmark-item-button"
	aria-label={existingBookmark ? 'Remove bookmark' : 'Bookmark item'}
	aria-pressed={!!existingBookmark}
	data-is-bookmarked={!!existingBookmark}
	data-testid="feed-item-viewer__bookmark-button"
	type="button"
	class={INTERACTION_BUTTON_CLASSES.button}
	on:click={handleBookmarkButtonClick}
	use:tippy={{
		content: existingBookmark ? 'Remove bookmark' : 'Bookmark item',
		placement: 'bottom'
	}}
>
	<span class={INTERACTION_BUTTON_CLASSES.icon}>
		<iconify-icon
			icon={existingBookmark ? 'solar:clipboard-remove-broken' : 'solar:bookmark-outline'}
			height="24px"
		/>
	</span>
	<span class={INTERACTION_BUTTON_CLASSES.text}> Bookmark </span>
</button>
