<!--
	@component

	FEED ITEM

	Displays a single feed item. 
	We got a shit ton of props on this one, many of them optional.


	@prop type - The type of feed item to display (Article or Post).
	@prop id - ID of the item.
	@prop publisher - Author of the item.
	@prop title - Title of the item.
	@prop content - Content/contentSnippet.
	@prop publishedAt - Date the item was created.
	@prop upvoteCount - The total number of upvotes cast on this item.
	@prop votes - Votes associated with the post.

	@prop avatarUrl - Avatar of the author.
	@prop contentSnippet - Content preview of the item.
	@prop img - Image associated with the post.
	@prop readingTime - Estimated reading time.
	
	@prop displayImage - Whether or not to display the image (default: false, Note: you MUST pass votes if you'd like footer to be displayed).
	@prop displayFooter - Whether or not to display the footer (default: false).
	@prop displayReadingTime - Whether or not to display the item's readig time (default: false).

-->
<!-- <svelte:options immutable={true} /> -->

<script context="module" lang="ts">
	export const FOOTER_BUTTON_CLASSES = `relative flex items-center justify-center rounded-[10px] p-2 hover:transition-all`;

	const MAX_CONTENT_LENGTH = 275;
</script>

<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import { transformUserMarkdown } from '@tickrr/lib/utils';
	import { createEventDispatcher } from 'svelte';

	import { type FeedLayout, getStores } from '../../../../stores';
	import { getFeedItemImagePath } from '../../../../utils/getFeedItemImagePath.ts';
	import Markdown from '../../elements/markdown/Markdown.svelte';
	import FeedItemImage from './../../elements/images/TwicFeedItemImage.svelte';
	import AuthorInfo from './_internal/AuthorInfo.svelte';
	import CommentButton from './_internal/CommentButton.svelte';
	import DateAndReadingTime from './_internal/DateAndReadingTime.svelte';
	import FeedItemWrapper from './_internal/FeedItem.Wrapper.svelte';
	import PublishedAtDate from './_internal/PublishedAtDate.svelte';
	import ShareButton from './_internal/ShareButton.svelte';
	import VoteButtons from './_internal/VoteButtons.svelte';
	import { getPublisherAvatarUrl, getPublisherName, getPublisherUsername } from './utils';

	const { settingsStore } = getStores(['settingsStore', 'sbStore']);

	// Props...
	export let mode: FeedLayout = $settingsStore.feedLayout;
	export let item: FEFeedItem;
	export let isPinned: boolean = false;
	export let disableFadeIn: boolean = false;
	export let displayImage: boolean = !(item.type === 'POST' && !item.img);
	export let displayContent: boolean = !displayImage;
	export let displayFooter: boolean = true;

	// Init Dispatcher && helpers.
	const dispatch = createEventDispatcher<{
		commentButtonClick: void;
	}>();

	function dispatchCommentButtonClick() {
		dispatch('commentButtonClick');
	}

	// Note: If any attribute needs to be bound, it needs to be passed
	// _directly_ below.
	$: feedItemId = item.id;
	$: feedItemType = item.type;
	$: content = item.content;
	$: pubDate = item.pub_date;
	$: title = item.title;
	$: readingTime = item.reading_time;
	$: imgPath = getFeedItemImagePath(item);

	$: authorName = getPublisherName(item);
	$: authorUsername = getPublisherUsername(item);
	$: authorAvatarUrl = getPublisherAvatarUrl(item);

	// Ensure that the mode is reactively updated, if the user changes it.
	// But only where an initial override value was not passed.
	$: if (!$$props.mode) mode = $settingsStore.feedLayout;
</script>

<FeedItemWrapper
	commentCount={item._count.comments}
	{disableFadeIn}
	{feedItemId}
	{mode}
	{pubDate}
	upvoteCount={item._count.votes}
	on:click
	on:keydown
>
	<!-- STATUS BADGE -->
	{#if isPinned}
		<div
			class="absolute left-4 top-0 h-[24px] -translate-y-full overflow-hidden border-b border-base group-hover:border-base"
		>
			<span
				class="
				variant-filled-primary
				block
				h-[24px] translate-y-[4px]
				rounded-tl-md rounded-tr-md
				px-2 pt-[2px] text-xs
				uppercase tracking-wider
				transition-all
				duration-150
				tablet:group-hover:translate-y-0
				"
			>
				PINNED
			</span>
		</div>
	{/if}

	<!-- HEADER && CONTENT -->
	<section class="flex w-full grow flex-col gap-y-2 px-2">
		<!-- HEADER -->
		<header class="flex h-8 w-full items-center gap-x-2 px-4 text-sm">
			<AuthorInfo
				{authorAvatarUrl}
				{authorName}
				{authorUsername}
				{feedItemId}
				{feedItemType}
			/>
			<div class="grow" />
			<PublishedAtDate publishedAt={pubDate} />
		</header>

		<!-- DATE & READING TIME -->
		<div
			class="flex min-h-[3rem] !shrink-0 grow flex-col justify-between gap-y-4 px-4 @xl:h-32"
		>
			<h3
				data-testid="feed-item__title"
				class="balance-text h4 line-clamp-3 align-bottom font-bold text-surface-50"
			>
				{title}
			</h3>

			<DateAndReadingTime classes="text-sm" {pubDate} {readingTime} />
		</div>

		<!-- CONTENT -->
		{#if displayImage}
			<FeedItemImage
				classes="aspect-video h-full w-[min(100%,40rem)] rounded-token pointer-events-none overflow-hidden brightness-75 transition-all group-hover:brightness-100"
				path={imgPath}
				publisher={{
					name: authorName,
					username: authorUsername
				}}
				{title}
			/>
		{:else if displayContent}
			<section class="-mx-2 flex overflow-hidden border-y border-base text-sm">
				<div class="mx-2 aspect-video h-full w-[9999px] overflow-hidden p-2">
					{#if content && content.length > 1}
						<Markdown
							source={transformUserMarkdown(
								`${content.slice(0, MAX_CONTENT_LENGTH)}${content.length > MAX_CONTENT_LENGTH ? '...' : ''}`,
								{ config: 'POST' }
							)}
						/>
					{:else}
						<p class="italic">No content to display.</p>
					{/if}
				</div>
			</section>
		{/if}
	</section>

	<!-- FOOTER -->
	{#if displayFooter}
		<footer class="place-items-center' mt-2 grid w-full grid-cols-3 text-xl">
			<VoteButtons
				{feedItemId}
				{feedItemType}
				isFeedItem
				bind:upvoteCount={item._count.votes}
				bind:votes={item.votes}
			/>

			<CommentButton
				commentCount={item._count.comments}
				on:click={dispatchCommentButtonClick}
			/>

			<ShareButton itemId={feedItemId} />
		</footer>
	{/if}
</FeedItemWrapper>
