<!--
	@component

	NEW COMMENT FORM

	Form with 1 input, allowing user to input post a new comment.
	Displayed at the bottom of PostViewerModal.

	@prop feedItemId - The id of the feed item.
	@prop focusCommentInput - Whether to focus the comment input.
-->
<script context="module" lang="ts">
	export type NewCommentDraft = {
		content: string;
		giphyUrl: null | string;
	};

	export async function scrollCommentInputIntoViewAndFocus(props?: { delay?: number }) {
		const delay = props?.delay ?? 0;
		if (delay) await new Promise((resolve) => setTimeout(resolve, delay));

		// We wait longer when FeedItemViewer is first opened, to allow data to load and
		// for the modal to transition into view.
		const triggerEl = document.querySelector(
			`#${NEW_COMMENT_INPUT_TRIGGER_ID}`
		) as HTMLButtonElement | null;

		if (!triggerEl) throw new Error('Could not find trigger element.');

		// Scroll trigger into view...
		scrollAndAutoFocus(triggerEl, 0);

		// Await element transitioning into view...
		await new Promise((resolve) => setTimeout(resolve, 0));

		// Open comment input...
		triggerEl.click();
	}
</script>

<script lang="ts">
	import { onMount } from 'svelte';

	import { scrollAndAutoFocus } from '../../../../../../../actions';
	import CommentInput from './CommentInput.svelte';
	import CommentInputTrigger, {
		NEW_COMMENT_INPUT_TRIGGER_ID
	} from './CommentInputTrigger.svelte';

	// Props...
	export let feedItemId: string;
	export let focusOnMount = false;

	// State...
	let displayCommentEditor: boolean = false;
	let newComment: NewCommentDraft = {
		content: '',
		giphyUrl: null
	};

	function resetNewComment() {
		newComment = {
			content: '',
			giphyUrl: null
		};
	}

	function closeEditor() {
		displayCommentEditor = false;
	}

	onMount(() => {
		if (focusOnMount) {
			scrollCommentInputIntoViewAndFocus();
		}
	});

	$: {
		// Reactively reset form when feedItemId changes.
		feedItemId; // This block will re-run every time feedItemId changes.
		resetNewComment();
		displayCommentEditor = false;
	}
</script>

<div id="comment-area" data-testid="comment-area" class="relative my-6">
	<div
		class="flex w-full flex-col rounded-2xl border-2 border-base bg-component focus-within:border-surface-300 hover:border-surface-300"
	>
		<!-- A. INPUT GROUP -->
		<CommentInputTrigger
			newCommentText={newComment.content}
			bind:displayCommentInput={displayCommentEditor}
		/>

		<!-- B. DROPDOWN TEXT AREA -->
		{#if displayCommentEditor}
			<div class="border-t border-base">
				<CommentInput
					{feedItemId}
					focusOnMount
					parentCommentId={null}
					type="COMMENT"
					bind:newComment
					on:commentAdded={closeEditor}
					on:commentAdded
				/>
			</div>
		{/if}
	</div>
</div>
