<script context="module" lang="ts">
	import { PUBLIC_GIPHY_SDK_KEY } from '$env/static/public';
	import { GiphyFetch } from '@giphy/js-fetch-api';

	export type GiphySelectorView = 'PREVIEW' | 'SEARCH' | 'TRENDING';

	export const QUERY_THROTTLE = 300;
	export const GIF_LOAD_LIMIT = 100;
	export const GUTTER = 6;
	export const LAYOUT = {
		mobile: {
			columns: 2,
			width: 320
		},
		tablet: {
			columns: 3,
			width: 320
		}
	};

	export const gf = new GiphyFetch(PUBLIC_GIPHY_SDK_KEY);
</script>
