import { TAG_REGEXES } from '../../constants/REGEX_PATTERNS.ts';

/**
 * Wraps tags in a given text with anchor tags.
 *
 * @param text - The text containing tags to be wrapped.
 * @returns The text with tags wrapped in anchor tags.
 */
export function wrapTagsInLinks(text: string): string {
	return text.replace(TAG_REGEXES.withWhitespace, (_, whitespace, tag) => {
		// I would prefer to use an HTML link (<a> tag) here, but using svelte-markdown,
		// HTML and Markdown text can NOT be mixed (at least within the same paragraph).
		// See https://www.npmjs.com/package/svelte-markdown#html-rendering.
		return `${whitespace}[#${tag}](/tag/${encodeURIComponent(tag)})`;
	});
}
