<script lang="ts">
	import SearchIcon from './Giphy.SearchIcon.svelte';
	export let value: string;
</script>

<div class="relative p-2">
	<span class="absolute left-5 top-1/2 z-10 -translate-y-1/2">
		<SearchIcon />
	</span>

	<input placeholder="Search GIPHY" type="text" class="input pl-10" bind:value />
</div>
