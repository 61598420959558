<!-- 
    @component
    
    VOTE BUTTONS
	
	Used both (1) FeedItem and (2) FeedItemViwer.
	Same component is used to reduce # of places mutation appears.
    
    @prop feedItemType - The type of feed item.
    @prop feedItemId - The ID of the feed item.
    @prop votes - The list of votes for the feed item.
	@prop upvoteCount - The number of upvotes for the feed item.
	@prop isFeedItem - Whether or not the component is being displayed in a FeedItem.

-->
<script context="module" lang="ts">
	const BASE_PLACEHOLDER_VOTE: Omit<Vote, 'type'> = {
		comment_id: null,
		created_at: new Date(),
		feed_item_id: 'PLACEHOLDER',
		id: 'PLACEHOLDER',
		profile_id: 'PLACEHOLDER'
	};
</script>

<script lang="ts">
	import type { FEFeedItem, Vote } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation, useQueryClient } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import _ from 'lodash';
	import { fly } from 'svelte/transition';

	import { tippy } from '../../../../../actions';
	import { clerk, constructAuthRedirect, openClerkModal } from '../../../../../auth';
	import { getStores } from '../../../../../stores';
	import { FOOTER_BUTTON_CLASSES } from '../FeedItem.svelte';

	const queryClient = useQueryClient();
	const toastStore = getToastStore();
	const modalStore = getModalStore();
	const { feedItemListStore } = getStores(['feedItemListStore']);

	// Props...
	export let feedItemType: FeedItemType;
	export let feedItemId: string;
	export let upvoteCount: FEFeedItem['_count']['votes'];
	export let votes: FEFeedItem['votes'];
	export let isFeedItem: boolean = false;

	// Static helpers...
	async function handleUnauthorizedClick() {
		modalStore.clear();
		await openClerkModal({
			clerk: $clerk,
			// If the user encountered the item as a FeedItem (in the feed), then just throw
			// them home. Otherwise, bring them back to the item once authenticated.
			redirectUrl: constructAuthRedirect(
				isFeedItem
					? undefined // use default redirect
					: `/i/${feedItemId}`
			),
			type: 'SIGN_IN'
		});
	}

	function handleMutationError(e: unknown) {
		if (dev) {
			logger.error({ error: e }, 'Error upvoting feed item.');
			toastStore.trigger({
				classes: 'toast-error',
				message: "We're sorry but something went wrong. Please try again."
			});
		}
	}

	async function refetchOnboardingData() {
		// We only need to refetch the onboarding checklist if the user has not completed it.
		if (!$page.data.profile?.is_onboarding_completed) {
			await queryClient.refetchQueries(['onboardingChecklist']);
		}
	}

	// We need to re-assign feedItemListStore to trigger a reactive update
	// (otherwise, the user's new vote will not be displayed in the feed).
	function updateFeedItemListStore({ uvCount, vList }: { uvCount: number; vList: Vote[] }) {
		const idx = $feedItemListStore.findIndex((item) => item.id === feedItemId);
		if (idx === -1) {
			logger.error({ id: feedItemId }, 'Feed item not found in store.');
			return;
		}
		const item = $feedItemListStore[idx];
		item.votes = vList;
		item._count.votes = uvCount;
		const newFeedItemListStore = [...$feedItemListStore];
		newFeedItemListStore[idx] = item;
		feedItemListStore.set(newFeedItemListStore);
	}

	// Set up vote mutation.
	const upvoteMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).vote.insert.mutate({
				feedItemId,
				type: 'UPVOTE'
			});
		},
		mutationKey: ['upvote', feedItemType, feedItemId, $page.data.profile?.id],
		onError: (e) => {
			// Reset state.
			votes = [];
			upvoteCount -= 1;
			handleMutationError(e);
		},
		onMutate: async () => {
			// Optimistically update state.
			const prev = { upvoteCount, votes };
			const placeholder: Vote = {
				...BASE_PLACEHOLDER_VOTE,
				profile_id: $page.data.profile?.id ?? 'PLACEHOLDER',
				type: 'UPVOTE'
			};
			votes = [placeholder];
			upvoteCount += 1;
			return { prev };
		},
		onSuccess: async ({ data: vote }) => {
			// Replace placeholder with actual vote.
			votes = [vote];
			updateFeedItemListStore({ uvCount: upvoteCount, vList: votes });
			await refetchOnboardingData();
		}
	});

	const downvoteMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).vote.insert.mutate({
				feedItemId,
				type: 'DOWNVOTE'
			});
		},
		mutationKey: ['downvote', feedItemType, feedItemId, $page.data.profile?.id],
		onError: (e) => {
			// Reset state.
			votes = [];
			handleMutationError(e);
		},
		onMutate: async () => {
			// Optimistically update state.
			const prev = { upvoteCount, votes };
			const placeholder: Vote = {
				...BASE_PLACEHOLDER_VOTE,
				profile_id: $page.data.profile?.id ?? 'PLACEHOLDER',
				type: 'DOWNVOTE'
			};
			votes = [placeholder];
			return { prev };
		},
		onSuccess: ({ data: vote }) => {
			// Replace placeholder with actual vote.
			// Note how we no-op voteCount here; only upvotes are counted.
			votes = [vote];
			updateFeedItemListStore({ uvCount: upvoteCount, vList: votes });
		}
	});

	const deleteVoteMutation = createMutation({
		mutationFn: async (type: 'DOWNVOTE' | 'UPVOTE') => {
			const payload = await trpc($page).vote.delete.mutate({ feedItemId });
			return { payload, type };
		},
		mutationKey: ['deleteVote', feedItemType, feedItemId, $page.data.profile?.id],
		onError: (e) => {
			// Reset state.
			// Note how we do not reset votes to its original state. We are unable to since
			// we have already purged the vote by this point.
			handleMutationError(e);
		},
		onMutate: async (type: 'DOWNVOTE' | 'UPVOTE') => {
			// Optimistically update state.
			const prev = { upvoteCount, votes };
			votes = [];
			if (type === 'UPVOTE') upvoteCount -= 1;
			return { prev, type };
		},
		onSuccess: async (res) => {
			// If res is undefined, something went wrong in the mutation fn above.
			// In essence, this mutation was called despite an existing vote not existing.
			const vote = _.first(res?.payload?.data);
			if (!res || !vote) {
				if (dev) {
					logger.warn(
						{ id: feedItemId, res },
						'No existing vote found, but deleteVote mutation was called anyways.'
					);
				}
				return;
			}

			// Update global state.
			updateFeedItemListStore({ uvCount: upvoteCount, vList: votes });
			await refetchOnboardingData();
		}
	});

	// Reactive state...
	$: isAuthenticated = !!$page.data.profile?.id;
	$: existingUpvote = votes?.find((v) => v.type === 'UPVOTE') ?? null;
	$: existingDownvote = votes?.find((v) => v.type === 'DOWNVOTE') ?? null;
	$: mutIsLoading =
		$upvoteMutation.isLoading || $downvoteMutation.isLoading || $deleteVoteMutation.isLoading;

	$: handleUpvoteClick = async () => {
		if (mutIsLoading) {
			if (dev) logger.debug('Mutation is loading. Ignoring click.');
			return;
		}

		if (!isAuthenticated) {
			await handleUnauthorizedClick();
			return;
		}

		if (existingUpvote) {
			await $deleteVoteMutation.mutateAsync('UPVOTE');
			return;
		}

		if (existingDownvote) {
			await $deleteVoteMutation.mutateAsync('DOWNVOTE');
			// Note: no return; we need to add the new upvote after.
		}

		await $upvoteMutation.mutateAsync();
	};

	$: handleDownvoteClick = async () => {
		if (mutIsLoading) {
			if (dev) logger.debug('Mutation is loading. Ignoring click.');
			return;
		}

		if (!isAuthenticated) {
			handleUnauthorizedClick();
			return;
		}

		if (existingDownvote) {
			await $deleteVoteMutation.mutateAsync('DOWNVOTE');
			return;
		}

		if (existingUpvote) {
			await $deleteVoteMutation.mutateAsync('UPVOTE');
			// Note: no return; we need to add the new downvote after.
		}

		await $downvoteMutation.mutateAsync();
	};
</script>

{#if isFeedItem}
	<!-- DISPLAYED IN FEEDITEM. -->
	<div
		data-is-downvoted={!!existingDownvote}
		data-is-upvoted={!!existingUpvote}
		data-testid="feed-item__upvote-button-container"
		class="
		group flex items-center justify-center gap-x-0
		{existingDownvote ? 'text-error-600 dark:text-error-400' : ''}
		{existingUpvote ? 'text-primary-700 dark:text-primary-500' : ''}
		"
	>
		<button
			name="upvote-button"
			aria-label="Upvote"
			aria-pressed={!!existingUpvote || !!existingDownvote}
			data-testid="feed-item__upvote-button"
			disabled={mutIsLoading}
			type="button"
			class="
			{FOOTER_BUTTON_CLASSES}
			{existingUpvote || existingDownvote
				? 'hover:variant-soft-surface hover:!bg-surface-300/20'
				: 'hover:variant-soft-primary'}
			"
			on:click|stopPropagation={handleUpvoteClick}
			use:tippy={{
				content: existingDownvote
					? 'Remove downvote'
					: existingUpvote
						? 'Remove upvote'
						: 'Upvote'
			}}
		>
			<!-- ICON -->
			{#if existingDownvote}
				<iconify-icon icon="solar:map-arrow-down-bold-duotone" />
			{:else if existingUpvote}
				<iconify-icon icon="solar:map-arrow-up-bold-duotone" />
			{:else}
				<iconify-icon icon="solar:map-arrow-up-outline" />
			{/if}
		</button>

		<!-- KARMA COUNT -->
		{#key upvoteCount}
			<span
				data-testid="feed-item__upvote-count"
				class="text-base font-semibold leading-4"
				in:fly={{ delay: 0, duration: 300, y: 20 }}
			>
				{upvoteCount > 0 ? upvoteCount : ''}
			</span>
		{/key}
	</div>
{:else}
	<!-- DISPLAYED IN INTERACTION BUTTONS -->
	<div
		data-is-downvoted={!!existingDownvote}
		data-is-upvoted={!!existingUpvote}
		data-testid="feed-item-viewer__vote-buttons-container"
		class="
		card
        variant-filled-surface
		relative
        flex
		scale-110
        space-x-1
		!rounded-2xl
		p-2
        shadow-md
		shadow-surface-900
		ring
		!ring-surface-300/25
		before:pointer-events-none
		before:absolute
		before:inset-0
		before:w-full
		before:rounded-2xl
		{existingUpvote ? 'before:!variant-ghost-success' : ''}
		{existingDownvote ? 'before:!variant-ghost-error' : ''}
		[&>button]:px-3
		[&>button]:py-2
        "
	>
		<!-- Upvote -->
		<button
			name="upvote-item-button"
			aria-label="Upvote item"
			aria-pressed={!!existingUpvote}
			data-testid="feed-item-viewer__upvote-button"
			disabled={mutIsLoading}
			type="button"
			class="rounded-[10px] hover:!bg-primary-500/20 hover:!text-primary-500"
			on:click={handleUpvoteClick}
			use:tippy={{
				content: existingUpvote ? 'Remove upvote' : 'Upvote',
				placement: 'bottom'
			}}
		>
			<span class="inline-flex items-center justify-center">
				<iconify-icon
					icon={existingUpvote
						? 'solar:map-arrow-up-bold-duotone'
						: 'solar:map-arrow-up-outline'}
					height="20px"
					class="
					inline-block
					{existingUpvote ? 'fill-current text-success-600 dark:text-success-400' : ''}
					"
				/>
			</span>
		</button>

		<!-- Downvote -->
		<button
			name="downvote-item-button"
			aria-label="Downvote item"
			aria-pressed={!!existingDownvote}
			data-testid="feed-item-viewer__downvote-button"
			disabled={mutIsLoading}
			type="button"
			class="rounded-[10px] !border-l-0 hover:!bg-error-500/20 hover:!text-error-400"
			on:click={handleDownvoteClick}
			use:tippy={{
				content: existingDownvote ? 'Remove downvote' : 'Downvote',
				placement: 'bottom'
			}}
		>
			<span class="inline-flex items-center justify-center">
				<iconify-icon
					icon={existingDownvote
						? 'solar:map-arrow-down-bold-duotone'
						: 'solar:map-arrow-down-outline'}
					height="20px"
					class="
					inline-block
					{existingDownvote ? 'fill-current text-error-600 dark:text-error-400' : ''}
					"
				/>
			</span>
		</button>
	</div>
{/if}
