<!-- 
    MARKDOWN LINK RENDERER
    @see https://github.com/pablo-abc/svelte-markdown
-->
<script lang="ts">
	export let text: string = '';
</script>

<blockquote class="blockquote border-l-2 border-primary-500">
	{text}
</blockquote>
