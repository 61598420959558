<script lang="ts">
	import type { FETicker } from '@tickrr/lib/types';

	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import SectionWrapper from './SectionWrapper.svelte';
	import TTLItem from './TrendingTickerListItem.svelte';

	const CACHE_TIME = 1000 * 60 * 10; // 10 minutes

	let allTickers: FETicker[] = [];
	let displayedTickers: FETicker[] = [];
	let isExpanded = false;

	const query = createQuery({
		cacheTime: CACHE_TIME,
		onError: (err) => logger.error({ error: err }, 'Error fetching trending ticker data'),
		placeholderData: JSON.parse(localStorage.getItem('trending-ticker-list') ?? '[]'),
		queryFn: async () => {
			const res = await trpc($page).ticker.fetchTrendingTickers.query();

			if (res.length === 0) {
				throw new Error('No trending tickers found');
			}

			localStorage.setItem('trending-ticker-list', JSON.stringify(res));

			return res;
		},
		queryKey: ['trending-ticker-list'],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		staleTime: CACHE_TIME
	});

	function toggleIsExpanded() {
		isExpanded = !isExpanded;
	}

	$: allTickers = $query.data ?? [];
	$: displayedTickers = isExpanded ? allTickers : allTickers.slice(0, 5);
</script>

<!-- If the query errors, this component will be hidden. -->
{#if $query.isLoading || allTickers.length > 0}
	<SectionWrapper classes="pb-0" testid="side-panel__trending-ticker-section">
		<h3 slot="header" class="px-6 text-base">Trending symbols</h3>

		{#if allTickers.length > 0}
			<ol class="list">
				{#each displayedTickers as item, idx (item.symbol + idx)}
					<TTLItem {idx} {item} />
				{/each}
			</ol>
		{:else}
			<div class="space-y-1">
				<!-- eslint-disable-next-line svelte/require-each-key, eslint-disable-next-line @typescript-eslint/no-unused-vars -->
				{#each Array(4) as _}
					<div class="px-2">
						<div class="skeleton btn flex h-20 w-full !py-2 px-4" />
					</div>
				{/each}
			</div>
		{/if}

		<button
			slot="footer"
			type="button"
			class="btn w-full py-5 text-sm"
			on:click={toggleIsExpanded}
		>
			{#if isExpanded}
				Show less
			{:else}
				Show more
			{/if}
		</button>
	</SectionWrapper>
{/if}
