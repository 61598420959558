<script lang="ts">
	import type { IGif } from '@giphy/js-types';

	import { createEventDispatcher } from 'svelte';

	import StaticImage from '../images/StaticImage.svelte';

	const dispatch = createEventDispatcher<{
		backToGridClick: void;
		gifSelected: { gif: IGif };
	}>();

	export let selectedGif: IGif;
</script>

<div data-testid="giphy-selector-preview" class="relative h-full">
	<!-- BACK TO FEED BUTTON -->
	<div class="absolute left-2 top-2 z-10">
		<button
			type="button"
			class="btn bg-component text-surface-50"
			on:click|stopPropagation={() => dispatch('backToGridClick')}
		>
			<iconify-icon icon="solar:alt-arrow-left-line-duotone"></iconify-icon>
			<span class="sr-only"> Back to grid </span>
		</button>
	</div>

	<!-- MAIN -->
	<div
		class="relative min-h-40 after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:via-transparent after:to-surface-900"
	>
		<!-- ATTRIBUTION IMAGE -->
		<StaticImage
			alt="Powered by GIPHY"
			src="/images/misc/giphy.gif"
			width="200"
			class="absolute bottom-2 left-2 z-20 w-40 rounded-md"
		/>

		<!-- IMAGE -->
		{#if selectedGif}
			<img
				alt={selectedGif.title}
				src={selectedGif.images.original.url}
				class="object-cover"
			/>
		{:else}
			<p>An error occurred.</p>
			<p>Please proceed back to the grid.</p>
		{/if}
	</div>
	<div class="flex flex-col p-2">
		<span class="line-clamp-1">{selectedGif?.title}</span>
		<span class="line-clamp-1 font-bold">@{selectedGif?.username}</span>
	</div>

	<!-- FOOTER -->
	<footer class="border-t border-base p-2">
		<button
			id="select-giphy-button"
			type="button"
			class="variant-filled btn w-full"
			on:click={() => dispatch('gifSelected', { gif: selectedGif })}
		>
			<span>Use GIF</span>
		</button>
	</footer>
</div>
