<script context="module" lang="ts">
	const MIN_TIME_TO_COUNT_AS_VIEW = 3000; // 3 seconds
</script>

<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { onDestroy } from 'svelte';

	export let id: FEFeedItem['id'];
	export let title: FEFeedItem['title'];

	let itemHasBeenInsertedOrUpdated = false;

	$: viewMutation = createMutation({
		mutationFn: async () => {
			if (!$page.data.profile?.id) return; // It's fine for this to fail silently for unlogged in users.

			if (dev) {
				logger.debug(
					{
						id,
						title
					},
					'Inserting new view for feed item'
				);
			}

			const view = trpc($page).view.insertNewViewIfNotExisting.mutate({
				feedItemId: id
			});

			return view;
		},
		onError: (err) => {
			logger.error({ error: err }, 'Error inserting new view for feed item');
		},
		onSettled: () => {
			itemHasBeenInsertedOrUpdated = true;
		},
		onSuccess: (data) => {
			if (dev) logger.info(data, 'Successfully inserted new view for feed item');
		}
	});

	// Reset itemHasBeenInsertedOrUpdated when FeedItem changes.
	$: {
		id;
		itemHasBeenInsertedOrUpdated = false;
	}

	// Track activity.
	let timeoutId: ReturnType<typeof setTimeout>;
	$: if (!itemHasBeenInsertedOrUpdated && !$viewMutation.isLoading) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			$viewMutation.mutate();
		}, MIN_TIME_TO_COUNT_AS_VIEW);
	}

	// Ensure the mutation is not triggered if user closes FeedItemViewer or leaves page.
	onDestroy(() => {
		clearTimeout(timeoutId);
	});
</script>

<slot />
