<!-- 
	@component
	
	FEED ITEM VIEWER COMMENT SECTION

	Displays a list of comments. Rendered by FeedItemViewer.

	@prop id - The ID of the feed item being viewed.
	@prop type - The type of the feed item being viewed.
	@prop isLoading - Whether or not the comments are loading.
	@prop isError - Whether or not there was an error loading the comments.
	@prop comments - The comments array (taken from the feed item selected in FeedItemViewer).

-->
<script lang="ts">
	import type { FEComment, FEFeedItem } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { ErrorState } from '../../../../../elements';
	import LoadingCommentTree from '../../loading/CommentTree.Loading.svelte';
	import CommentTree from './CommentTree.svelte';

	export let id: FEFeedItem['id'];
	export let type: FeedItemType;
	export let isLoading: boolean;
	export let isError: boolean;
	export let comments: FEComment[];
</script>

<section
	data-loading={isLoading}
	data-testid="feed-item-viewer__comments-section"
	class="relative grow"
>
	<h2 class="sr-only">Comments</h2>

	{#if isLoading}
		<LoadingCommentTree />
	{:else if isError}
		<ErrorState
			classes="py-20"
			description="There was an error loading the comments. <br /> If the problem persists, please contact support using the button below."
		/>
	{:else if comments.length > 0}
		<CommentTree
			{comments}
			parentFeedItemId={id}
			parentFeedItemType={type}
			on:commentUpdated
			on:replyAdded
			on:commentDeleted
		/>
	{:else}
		<div
			class="flex h-32 w-full flex-col items-center justify-center gap-y-2 p-4 tablet:h-64 tablet:pb-8"
		>
			<iconify-icon icon="solar:chat-line-outline" height="24px" />

			<p class="text-center">Be the first to comment.</p>
		</div>
	{/if}
</section>
