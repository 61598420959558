import { logger } from '@tickrr/lib/logger';

/**
 * Opens the native share menu on the device, allowing the user to share the current page.
 * @returns A Promise that resolves when the share menu is closed.
 */
const openNavigatorShareMenu = async (shareData: ShareData) => {
	const { text, title, url } = shareData;
	try {
		await navigator.share({
			text,
			title,
			url
		});
	} catch (error) {
		logger.error({ error }, 'Error opening share menu:');
	}
};

/**
 * Attaches a click event listener to the given node that opens the native web share menu when triggered.
 * @param node - The HTML element to attach the event listener to.
 * @param shareData - The data to be shared through the Web Share API.
 * @returns An object with a `destroy` method that removes the event listener from the node when called.
 */
export function webshare(
	node: HTMLElement,
	{ enabled, shareData }: { enabled: boolean; shareData: ShareData }
) {
	// Only attach the event listener if the Web Share API is supported.
	if (!enabled) return;

	node.addEventListener('click', () => openNavigatorShareMenu(shareData));

	// Detach the event listener when the component is destroyed.
	return {
		destroy() {
			node.removeEventListener('click', () => openNavigatorShareMenu(shareData));
		}
	};
}
