<script lang="ts">
	import { type CssClasses } from '@skeletonlabs/skeleton';

	import AnimatedBorderCard from '../../elements/animated/AnimatedBorderCard.svelte';
	import StaticImage from '../../elements/images/StaticImage.svelte';

	const copy = {
		img: '/images/screenshots/feed/desktop-search.webp',
		subtitle: 'Access your favorite news <br /> feed on every new tab',
		title: 'Get the extension today!',
		url: '/extension/install'
	};

	export let classes: CssClasses = '';
	export let background: null | string = null;
</script>

<AnimatedBorderCard
	{background}
	borderColor="primary"
	borderRadius="1rem"
	classes="btn !p-0"
	duration={8750}
>
	<a
		aria-label="Sign up for a free account"
		data-testid="side-panel__sign-up-button"
		href={copy.url}
		rel="noopener noreferrer"
		target="_blank"
		class="
			relative block
			h-full w-full
			shrink-0
			rounded-2xl
			bg-gradient-to-br
			from-surface-600
			via-surface-800
			to-surface-900
			pt-8
			text-center
			font-bold
			text-surface-50
			{!copy.img ? 'pb-8' : ''}
			{classes}
			"
		on:click
	>
		<p class="mb-2 shadow-black text-shadow-sm">
			{copy.title}
		</p>
		<p class="text-sm shadow-black text-shadow-sm">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html copy.subtitle}
		</p>

		{#if copy.img}
			<div class="mt-5 h-32 overflow-hidden pl-4 laptop:pl-8">
				<div class="relative w-[200%] overflow-hidden rounded-md border border-base">
					<StaticImage alt="" src={copy.img} />
				</div>
			</div>
		{/if}
	</a>
</AnimatedBorderCard>
