<!-- 
	@component
	
	DELETE BUTTON

	@prop feedItemId - The ID of the feed item to delete.
-->
<script lang="ts">
	import type { Comment } from '@tickrr/db';

	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { createEventDispatcher } from 'svelte';

	import { ConfirmActionButtons } from '../../../../../elements';

	const dispatch = createEventDispatcher<{ commentDeleted: { deletedComment: Comment } }>();

	const toastStore = getToastStore();

	// Props...
	export let commentId: string;
	export let displayConfirmationButtons: boolean;

	// Set up delete mutation.
	const deleteMutation = createMutation({
		mutationFn: async () => {
			return trpc($page).comment.delete.mutate({ commentId });
		},
		mutationKey: ['comment-deleteClick', commentId],
		onError: (e) => {
			logger.warn({ error: e }, 'Error deleting comment.');

			toastStore.trigger({
				classes: 'toast-error',
				message: "We're sorry but something went wrong. Please try again."
			});
		},
		onSuccess: (data) => {
			dispatch('commentDeleted', { deletedComment: data });
			// Show toast.
			toastStore.trigger({
				classes: 'toast',
				message: 'Your comment has been successfully deleted.'
			});
		}
	});

	function submitReport() {
		if (!$deleteMutation.isLoading && !($deleteMutation.isError || $deleteMutation.isSuccess)) {
			$deleteMutation.mutate();
		}
	}
</script>

<ConfirmActionButtons
	testid="comment__delete-confirmation-menu"
	bind:displayConfirmationButtons
	on:confirmClick|once={submitReport}
	let:openConfirmationMenu
>
	<button
		aria-label="Open delete confirmation dropdown"
		data-testid="comment__delete-button"
		type="button"
		class="popup-menu-button"
		on:click={openConfirmationMenu}
	>
		<span class="text-error-500">
			<iconify-icon inline icon="solar:trash-bin-trash-linear" />
		</span>
		<span class="text-error-500"> Delete </span>
	</button>
</ConfirmActionButtons>
