<script lang="ts">
	import { calculateTimeSince } from '@tickrr/lib/utils';

	import { tippy } from '../../../../../../../actions';

	// Props
	export let updatedAt: Date | null;
</script>

{#if updatedAt}
	{@const coercedDate = new Date(updatedAt)}
	{@const timeSinceLastEdit = calculateTimeSince(coercedDate)}
	<div
		data-tippy-content="Updated at {coercedDate.toLocaleDateString(undefined, {
			day: 'numeric',
			month: 'long',
			weekday: 'long',
			year: 'numeric'
		})} at {coercedDate.toLocaleTimeString(undefined, {
			hour: '2-digit',
			minute: '2-digit'
		})}"
		class="flex h-fit items-center gap-x-[4px] truncate"
		use:tippy
	>
		<span class="line-clamp-1">
			updated
			{timeSinceLastEdit ?? 'unknown'}
			ago
		</span>
	</div>
{/if}
