//
// Action: Clipboard
type ClipboardArgs = { content: string; enabled: boolean };

/**
 * Copies the given data to the clipboard.
 * @param data The data to be copied.
 * @param mimeType The MIME type of the data. Defaults to 'text/plain'.
 */
async function copyToClipboard(data: BlobPart, mimeType = 'text/plain') {
	if (navigator.clipboard.write) {
		await navigator.clipboard.write([
			new ClipboardItem({
				[mimeType]: new Blob([data], {
					type: mimeType
				}),
				['text/plain']: new Blob([data], {
					type: 'text/plain'
				})
			})
		]);
	} else {
		// fallback since .writeText has wider browser support
		await new Promise((resolve) => {
			resolve(navigator.clipboard.writeText(String(data)));
		});
	}
}

/**
 * Attaches a click event listener to the given HTMLElement that copies the specified content to the clipboard
 * when clicked. The `enabled` property determines whether the copy functionality is enabled or not.
 *
 * @param node - The HTMLElement to attach the click event listener to.
 * @param args - An object containing the content to copy and whether the copy functionality is enabled.
 * @returns An object with `update` and `destroy` methods for updating and destroying the clipboard action.
 */
export function clipboard(node: HTMLElement, args: ClipboardArgs) {
	const { content, enabled } = args;

	// Ensure we have clipboard enabled on this particular component.
	if (!enabled) return;

	// Ensure the browser supports the Clipboard API.
	if (!window.isSecureContext) {
		// eslint-disable-next-line no-console
		console.error(
			'Clipboard action failed: app not running in secure context, see: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard.'
		);
		return;
	}

	// Define handler.
	const onClick = async () => {
		// Handle everything else.
		await copyToClipboard(content);
		node.dispatchEvent(new CustomEvent('copyComplete'));
	};

	// Event Listener
	node.addEventListener('click', onClick);

	// Lifecycle
	return {
		destroy() {
			node.removeEventListener('click', onClick);
		},
		update(newArgs: ClipboardArgs) {
			args = newArgs;
		}
	};
}
