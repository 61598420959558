import { INSTRUMENT_SYMBOL_REGEXES } from '../../constants/REGEX_PATTERNS.ts';

/**
 * Wraps securities in the given text with anchor tags.
 *
 * @param text - The input text to process.
 * @returns The processed text with securities wrapped in anchor tags.
 */
export function wrapSecuritiesInLinks(text: string): string {
	return text.replace(INSTRUMENT_SYMBOL_REGEXES.withWhitespace, (_, whitespace, symbol) => {
		// return text.replace(/(^|\s)\$(\w+)\b/g, (_, whitespace, symbol) => {
		// I would prefer to use an HTML link (<a> tag) here, but using svelte-markdown,
		// HTML and Markdown text can NOT be mixed (at least within the same paragraph).
		// See https://www.npmjs.com/package/svelte-markdown#html-rendering.
		return `${whitespace}[$${symbol}](/ticker/${encodeURIComponent(symbol)})`;
	});
}
