<!-- 
	@component
	
	FEED ITEM VIEWER

	Displays a feed item in a modal. Rendered by FeedItemCard.

	@prop item - The feed item to display.
	@prop YMALItems - The items to display in the side panel.
	@prop focusCommentInput - Whether or not to focus the comment input.
	@prop classes - CSS classes to apply to the root element.
	@prop mainClasses - CSS classes to apply to the main element.
	@prop sidePanelClasses - CSS classes to apply to the side panel element.

-->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';
	import type { FEFeedItem } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { generateShareableLink } from '@tickrr/lib/utils';
	import { trpc } from '@tickrr/trpc/client';
	import _ from 'lodash';

	import type { AuthorInfoSocials } from './_internal/side-panel/AuthorInfo.svelte';

	import { getStores } from '../../../../stores';
	import { getFeedItemImagePath } from '../../../../utils';
	import ItemViewerSkeleton from './_internal/FeedItemViewerShell.svelte';
	import ViewMutationProvider from './_internal/ViewMutationProvider.svelte';
	import Body from './_internal/main/Body.svelte';
	import NewCommentForm from './_internal/main/comment-input/NewCommentForm.svelte';
	import CommentSection from './_internal/main/comments/CommentSection.svelte';
	import InteractionButtons from './_internal/main/interaction-buttons/InteractionButtons.svelte';
	import SidePanel from './_internal/side-panel/SidePanel.svelte';

	const { isExtensionStore } = getStores(['isExtensionStore']);

	export let item: FEFeedItem;
	export let focusCommentInput = false;
	export let classes: CssClasses = '';
	export let mainClasses: CssClasses = '';
	export let sidePanelClasses: CssClasses = '';

	// For all of these variables, we need to index into the item object.
	let publisherId: string;
	let publisherName: string;
	let publisherUsername: string;
	let publisherAvatarUrl: null | string = null;
	let authorName: null | string = null;
	let bio: null | string = null;
	let socials: AuthorInfoSocials = null;

	// Declare reactive variables.
	// $: item = $feedItemListStore[activeIdx];
	$: feedItemType = item.type as FeedItemType;
	$: id = item.id;
	$: title = item.title;
	$: content = item.content;
	$: url = item.url ?? generateShareableLink({ isExtension: $isExtensionStore, itemId: id });
	$: publishedAt = item.pub_date;
	$: tldr = item.tldr ?? item.content;
	$: readingTime = item.reading_time ?? _.random(3, 8);
	$: tags = item.tags;
	$: imgPath = getFeedItemImagePath(item);

	$: if (feedItemType === 'ARTICLE' && item.publisher) {
		// For articles, we will use the content as the TLDR if no TLDR is provided.
		tldr = item.tldr ?? item.content;
		publisherId = item.publisher.id;
		publisherAvatarUrl = item.publisher.avatar_url;
		publisherUsername = item.publisher.username;
		publisherName = item.publisher.name;
		authorName = item.author;
		socials = {
			linkedIn: null,
			website: { url: item.publisher.url }
		};
	} else if (feedItemType === 'POST' && item.profile) {
		publisherId = item.profile.id;
		publisherAvatarUrl = item.profile.avatar_url;
		publisherUsername = item.profile.username;
		publisherName = item.profile.username;
		bio = item.profile.bio;
		socials = {
			linkedIn: { url: item.profile.linkedin },
			website: null
		};
	} else if (feedItemType === 'DAILY_DISCUSSION' || feedItemType === 'UPDATE') {
		publisherUsername = 'tickrr';
		publisherName = 'Tickrr.io';
		publisherAvatarUrl = 'tickrr.svg';
	} else {
		if (dev)
			logger.error(
				{ feedItemType, item },
				`FeedItemViewer: type ${feedItemType} is not a valid FeedItemType.`
			);

		// These values need defaults; fatal if they do not get set.
		publisherId = 'NOT FOUND';
		publisherName = 'NOT FOUND';
		publishedAt = new Date();
	}

	$: commentsQuery = createQuery({
		queryFn: async () => {
			return await trpc($page).comment.fetchByItemId.query({
				itemId: id,
				itemType: feedItemType
			});
		},
		queryKey: ['comments', feedItemType, id],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false
	});

	const refetchComments = () => {
		$commentsQuery.refetch();
	};

	// Calculate reactive values.
	$: commentCount = $commentsQuery.data?.length ?? 0;
</script>

<ViewMutationProvider {id} {title} />

<!-- ITEM VIEWER BODY -->
<ItemViewerSkeleton {classes} feedItemId={id} {mainClasses} {sidePanelClasses}>
	<!-- A. MAIN -->
	<svelte:fragment slot="main">
		<slot name="main-header" />
		<!--  BODY  -->
		<Body
			{commentCount}
			{content}
			{feedItemType}
			{imgPath}
			{publishedAt}
			{publisherAvatarUrl}
			{publisherName}
			{publisherUsername}
			{readingTime}
			{tags}
			{title}
			{tldr}
			upvoteCount={item._count.votes}
			{url}
		/>

		<!-- 
            INTERACT BUTTONS (Like, Comment, etc.) 
            Note how this is bound to the item's votes directly.
            This is the only way to ensure the item is globally and updates will persist.
        -->
		<InteractionButtons
			authorId={publisherId}
			feedItemId={id}
			{feedItemType}
			{title}
			bind:upvoteCount={item._count.votes}
			bind:votes={item.votes}
		/>

		<!-- 
            NEW COMMENT FIELD (fixed footer) 
            Note how this is bound to the item's comments directly. 
            This is the only way to ensure the item is globally and updates will persist.
        -->
		<NewCommentForm
			feedItemId={id}
			focusOnMount={focusCommentInput}
			on:commentAdded={refetchComments}
		/>

		<!-- 
            COMMENTS
            Note: the below height matches the height of the emptyCommentList component.
        -->
		<CommentSection
			{id}
			comments={$commentsQuery.data ?? []}
			isError={$commentsQuery.isError}
			isLoading={$commentsQuery.isLoading || $commentsQuery.isFetching}
			type={feedItemType}
			on:commentUpdated={refetchComments}
			on:replyAdded={refetchComments}
			on:commentDeleted={refetchComments}
		/>
	</svelte:fragment>

	<!-- B. SIDEPANEL -->
	<svelte:fragment slot="side-panel">
		<SidePanel
			{authorName}
			{bio}
			itemId={id}
			itemType={feedItemType}
			{publishedAt}
			publisherAvatar={publisherAvatarUrl}
			{publisherName}
			{publisherUsername}
			{socials}
			{title}
			on:YMALClick
		/>
	</svelte:fragment>
</ItemViewerSkeleton>
