<!-- 
    MARKDOWN HEADING RENDERER
    @see https://github.com/pablo-abc/svelte-markdown
-->
<script lang="ts">
	export let text: string;
	export let depth: number;
</script>

{#if depth === 1}
	<h1 class="h2 my-2 font-bold text-surface-50">
		{text}
	</h1>
{:else if depth === 2}
	<h2 class="h3 my-2 font-bold text-surface-50">
		{text}
	</h2>
{:else if depth === 3}
	<h3 class="h4 font-bold text-surface-50">
		{text}
	</h3>
{:else if depth === 4}
	<h4 class="h5">
		{text}
	</h4>
{:else if depth === 5}
	<h5 class="h6">
		{text}
	</h5>
{:else if depth === 6}
	<h6 class="h6">
		{text}
	</h6>
{:else}
	<!-- Provide a default just in case. -->
	<h2 class="h3">
		{text}
	</h2>
{/if}
