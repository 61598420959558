<script lang="ts">
	import { page } from '$app/stores';
	import { getModalStore } from '@skeletonlabs/skeleton';

	import { clerk, constructAuthRedirect, openClerkModal } from '../../../../auth';

	const modalStore = getModalStore();
	export let redirectUrl: string = $page.url.pathname;
	export let classes: string = '';

	async function handleClick() {
		modalStore.clear();
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect(redirectUrl),
			type: 'SIGN_UP'
		});
	}
</script>

<button
	type="button"
	class="variant-filled btn py-4 font-bold max-tablet:w-full {classes}"
	on:click={handleClick}
>
	<span> Join today </span>
	<span class="text-surface-900/60">&#8212; completely free </span>
</button>
