<!-- 
    @component
    
    SUBMIT BUTTON 
	
	@prop {boolean} disabled - Whether or not the button is disabled.
	@prop {boolean} isLoading - Whether or not the button is loading.
	@prop {string} arialabel - The button's aria label.
	@prop {string} redirectUrl - The button's redirect URL.
	@prop {string} variant - The button's variant.
	@prop {string} padding - The button's padding.
	@prop {string} classes - The button's classes.

-->

<script lang="ts">
	import { ProgressRadial } from '@skeletonlabs/skeleton';

	import { SignedIn, SignedOut } from '../../../../auth';
	import JoinNowButton from './JoinNowButton.svelte';

	export let disabled: boolean;
	export let isLoading: boolean;
	export let arialabel: string | undefined = undefined;
	export let redirectUrl: string = '/home';
	export let variant: string = 'variant-filled';
	export let padding: string = '!px-8';
	export let classes: string = '';
</script>

<SignedIn>
	<button
		aria-disabled={isLoading}
		aria-label={arialabel}
		{disabled}
		type="button"
		class="{variant} btn {padding} {classes}"
		on:click
	>
		{#if isLoading}
			<span class="px-2">
				<ProgressRadial class="h-6 w-6" />
			</span>
		{:else}
			<slot />
		{/if}
	</button>
</SignedIn>

<SignedOut>
	<JoinNowButton {redirectUrl} />
</SignedOut>
