<script lang="ts">
	import StaticImage from '../../../../../elements/images/StaticImage.svelte';

	export let gifUrl: string;
</script>

<a
	href={gifUrl}
	rel="noreferrer noopener"
	target="_blank"
	class="relative block w-fit max-w-xs overflow-hidden border border-base rounded-token"
	on:click|stopPropagation
>
	<img alt="Post GIPHY" data-testid="comment__gif" height="150" src={gifUrl} width="200" />
	<StaticImage
		alt="Powered by GIPHY"
		height="42"
		src="/images/misc/giphy.gif"
		width="200"
		class="w-full rounded-md"
	/>
</a>
