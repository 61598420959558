<script context="module" lang="ts">
	export const FALLBACK_AVATAR = 'tickrr.svg';
</script>

<script lang="ts">
	import { Avatar } from '../../../elements';

	// Props...
	export let publisherAvatarUrl: null | string;
	export let width: string = 'w-8';
	export let height: string = 'h-8';
	export let testid: string = 'feed-item__author-avatar';
	export let rounded: string = 'rounded-[8px]';
	export let borderWidth: string = 'ring-1';

	$: borderColor =
		publisherAvatarUrl === FALLBACK_AVATAR ? 'ring-primary-500' : 'ring-surface-300';
</script>

<div data-testid={testid} class="{height} {width} shrink-0 duration-0">
	<Avatar
		classes="{rounded} {borderWidth} {borderColor}"
		{height}
		path={publisherAvatarUrl}
		{rounded}
		{width}
	/>
</div>
