/**
 * Prevents arrow key events from propagating.
 *
 * @param node - The HTML element to attach the event listener to.
 * @returns An object with a `destroy` method to remove the event listener.
 */
export function preventArrowKeyEventsFromPropogating(node: HTMLElement) {
	function handleKeydown(event: KeyboardEvent) {
		if (
			event.key === 'ArrowUp' ||
			event.key === 'ArrowDown' ||
			event.key === 'ArrowLeft' ||
			event.key === 'ArrowRight'
		) {
			event.stopPropagation();
		}
	}

	node.addEventListener('keydown', handleKeydown);

	return {
		destroy() {
			node.removeEventListener('keydown', handleKeydown);
		}
	};
}
