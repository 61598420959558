<!--
	@component

	COMMENT - Vote Button

	@prop parentId - The ID of the parent comment.
	@prop votes - An array of Vote entries fk'd to this post.
	@prop classes - Additional classes to apply to the button.

-->
<script context="module" lang="ts">
	const BASE_PLACEHOLDER_VOTE: Omit<Vote, 'type'> = {
		comment_id: 'PLACEHOLDER',
		created_at: new Date(),
		feed_item_id: null,
		id: 'PLACEHOLDER',
		profile_id: 'PLACEHOLDER'
	};
</script>

<script lang="ts">
	import type { Vote } from '@tickrr/db';

	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { type CssClasses, getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	import { tippy } from '../../../../../../../actions';
	import { clerk, openClerkModal } from '../../../../../../../auth';
	import { constructAuthRedirect } from '../../../../../../../auth/constructAuthRedirect.ts';
	import { COMMENT_FOOTER_BUTTON_CLASSES } from './Comment.Footer.svelte';

	const modalStore = getModalStore();
	const toastStore = getToastStore();

	// Props
	export let feedItemId: string;
	export let commentId: string;
	export let votes: Vote[] = [];
	export let upvoteCount: null | string;
	export let classes: CssClasses = '';

	let vcAsInt: number = 0;

	// Helpers
	async function handleUnauthorizedClick() {
		modalStore.clear();
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect('/i/' + feedItemId),
			type: 'SIGN_IN'
		});
	}

	function handleMutationError() {
		toastStore.trigger({
			classes: 'toast-error',
			message: "We're sorry but something went wrong. Please try again."
		});
	}

	const deleteUpvoteMutation = createMutation({
		mutationFn: async ({ currVote }: { currVote: Vote }) => {
			if (!currVote) return;
			const res = await trpc($page).vote.delete.mutate({
				commentId
			});
			return { currVote, res };
		},
		mutationKey: [
			`delete-comment-upvote-${commentId}`
			/**
			 * Though upvoting is depending on the Comment, we do not
			 * include it as a dependency; we can handle things ourselves and do not need it to refresh..
			 * */
		],
		onError: () => {
			// Note how we do not reverse the optimistic update here.
			// The vote is already removed by the time we reach this point,
			// so we have no way to reverse it.
			handleMutationError();
		},
		onMutate: () => {
			// Optimistically update state.
			const prev = { votes };
			votes = [];
			vcAsInt -= 1;
			return { prev };
		},
		onSuccess: (data) => {
			if (data === undefined) {
				logger.warn(
					'No data returned from deleteUpvoteMutation. This likely means that the user did not have an existing vote but the mutation was called anyways.'
				);
				return;
			}
			if (dev) logger.debug({ data }, 'Successfully removed upvote from DB and local state');
		}
	});

	const upvoteCommentMutation = createMutation({
		mutationFn: async () => {
			const res = await trpc($page).vote.insert.mutate({
				commentId: commentId,
				type: 'UPVOTE'
			});
			return res;
		},
		mutationKey: [
			`comment-upvote-${commentId}`
			/**
			 * Though upvoting is depending on the Comment, we do not
			 * include it as a dependency; we can handle things ourselves and do not need it to refresh..
			 * */
		],
		onError: () => {
			handleMutationError();
		},
		onMutate: () => {
			// Optimistically update state.
			const prev = { votes };
			const placeholder: Vote = {
				...BASE_PLACEHOLDER_VOTE,
				profile_id: $page.data.profile?.id ?? 'PLACEHOLDER',
				type: 'UPVOTE'
			};
			votes = [placeholder];
			vcAsInt += 1;
			return { prev };
		},
		onSuccess: (res) => {
			// Replace the existing placeholder with the actual vote.
			votes = [res.data];
			logger.debug('New upvote inserted to DB and added to local state: ', res.data);
		}
	});

	// Reactive state
	$: isAuthenticated = !!$page.data.profile?.id;
	$: existingUpvote =
		votes.find(
			(vote) => vote.type === 'UPVOTE' && vote.profile_id === $page.data.profile?.id
		) ?? null;
	$: if (upvoteCount !== null) {
		try {
			vcAsInt = parseInt(upvoteCount);
		} catch {
			vcAsInt = -999; // Use an unusual number so we can more easily catch any errors if they arise.
		}
	}

	$: handleClick = async () => {
		if (!isAuthenticated) {
			await handleUnauthorizedClick();
			return;
		}

		// Handle existing upvote.
		if (existingUpvote) {
			await $deleteUpvoteMutation.mutateAsync({ currVote: existingUpvote });
			return;
		}

		// Else, handle new upvote.
		await $upvoteCommentMutation.mutateAsync();
	};
</script>

<div
	class="
		flex items-center justify-center gap-x-1
		{existingUpvote ? 'text-primary-700 dark:text-primary-500' : 'text-inherit'}
		{classes}
		"
>
	<!-- BUTTON -->
	<button
		name="comment-upvote-button"
		aria-label="Upvote comment"
		aria-pressed={!!existingUpvote}
		data-testid="comment__upvote-button"
		disabled={$upvoteCommentMutation.isLoading || $deleteUpvoteMutation.isLoading}
		type="button"
		class="
		{COMMENT_FOOTER_BUTTON_CLASSES}
		{existingUpvote ? 'hover:variant-soft-surface hover:!bg-component' : 'hover:variant-soft-primary'}
		"
		on:click|stopPropagation|preventDefault={handleClick}
		use:tippy={{ content: existingUpvote ? 'Remove upvote' : 'Upvote' }}
	>
		<iconify-icon
			inline
			icon={existingUpvote ? 'solar:map-arrow-up-bold-duotone' : 'solar:map-arrow-up-outline'}
			height="1.125em"
		/>
		<span class="sr-only">
			{existingUpvote ? 'Remove upvote' : 'Upvote'}
		</span>
	</button>

	<!-- KARMA COUNT -->
	{#if vcAsInt > 0}
		<span data-testid="comment__upvote-count" class="text-lg leading-6">
			{vcAsInt}
		</span>
	{/if}
</div>
