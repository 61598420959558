import { generateShareableLink } from './generateShareableLink';

/**
 * Generate input to be used with the Web Share API.
 * @param itemId The ID of the item to be shared through the Web Share API.
 * @param title The title of the item to be shared through the Web Share API.
 * @returns An object containing the input to be used with the Web Share API.
 */
export function generateWebshareData({
	itemId,
	title
}: {
	itemId: string;
	title: string;
}): ShareData {
	const shareData = {
		text: 'Check out this article on Tickrr: ' + title,
		title,
		url: generateShareableLink({ itemId })
	};

	return shareData;
}
