<!--
	@component

	NEW REPLY FORM

	Displays a form with 2 inputs to allow a user to submit a new reply (to a parent comment).
	
	@prop isExpanded - Whether or not the form is expanded.
	@prop isChild - Whether or not the comment is a child of another comment.
	@prop parentFeedItemId - The ID of the parent item (Post, Article, or Update) to which this comment belongs.
	@prop parentCommentId - The ID of the comment to which this reply is being added.
-->
<script lang="ts">
	import type { Comment } from '@tickrr/db';

	import type { NewCommentDraft } from './NewCommentForm.svelte';

	import CommentInput from './CommentInput.svelte';

	// Props...
	export let isExpanded: boolean; // Whether *textarea* should be expanded (not entire dropdown)
	export let isChild: boolean;
	export let parentFeedItemId: string;
	export let parentCommentId: Comment['id'];

	// State...
	let newReply: NewCommentDraft = {
		content: '',
		giphyUrl: null
	};

	function closeTextArea() {
		isExpanded = false;
	}
</script>

<!-- INPUT GROUP -->
<div
	data-testid="comment__new-reply-form"
	class="-mx-4 mt-4 space-y-2 {isChild ? '-ml-2 tablet:-ml-4' : ''}"
>
	<div class="border-t border-base">
		<CommentInput
			actionBarClasses="pb-0"
			feedItemId={parentFeedItemId}
			focusOnMount
			{parentCommentId}
			testid="new-reply-form"
			type="REPLY"
			bind:newComment={newReply}
			on:replyAdded={closeTextArea}
			on:replyAdded
		>
			<svelte:fragment slot="pre-action-bar">
				<button
					type="button"
					class="text-surface-500-400-token btn mt-3 w-full !rounded-none"
					on:click={closeTextArea}
				>
					<iconify-icon icon="tabler:chevrons-up" width="20" />
					<span> Toggle up </span>
				</button>
			</svelte:fragment>
		</CommentInput>
	</div>
</div>
