<script lang="ts">
	export let editMenuIsDisplayed: boolean = false;

	function openCommentEditMenu() {
		editMenuIsDisplayed = true;
	}
</script>

<button
	aria-label="Open edit menu"
	data-testid="comment__edit-button"
	type="button"
	class="popup-menu-button"
	on:click={openCommentEditMenu}
>
	<span>
		<iconify-icon inline icon="solar:gallery-edit-outline" />
	</span>
	<span> Edit </span>
</button>
