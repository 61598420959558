<script context="module" lang="ts">
	export type YMALItem = FEFeedItem;

	export type YMALItemClickDetails = {
		item: YMALItem;
	};
</script>

<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import _ from 'lodash';
	import { createEventDispatcher } from 'svelte';

	import ErrorState from '../../../../elements/errors/ErrorState.svelte';
	import Avatar from '../../../../elements/images/TwicAvatar.svelte';
	import SectionWrapper from './SectionWrapper.svelte';

	const dispatch = createEventDispatcher<{ YMALClick: YMALItemClickDetails }>();

	// Props...
	export let itemId: string;
	export let padding: string = 'pb-2';

	// Helpers...
	const dispatchYMALItemClick = ({ item }: { item: YMALItem }) => {
		// Dispatch message.
		const details: YMALItemClickDetails = {
			item
		};

		dispatch('YMALClick', details);
	};

	function getAvatarPath(item: YMALItem) {
		switch (item.type) {
			case 'ARTICLE':
				return item.publisher?.avatar_url ?? null;
			case 'POST':
				return item.profile?.avatar_url ?? null;
			case 'DAILY_DISCUSSION':
			case 'UPDATE':
			default:
				return 'tickrr-text.svg';
		}
	}

	$: query = createQuery({
		enabled: true, // wait for item to be defined
		onError: (err) => {
			logger.warn({ error: err }, 'Could not fetch YMALItems');
		},
		queryFn: async () => {
			let items = await trpc($page).feed.fetchFeedItems.query({
				fresh: true,
				skip: _.random(0, 8), // Randomize picks to ensure items are unique on page change.
				sort: 'POPULAR',
				take: 4,
				types: null
			});

			// We prefer not to show the current item as a suggested YMAL item, so filter it out.
			items = items.filter((i) => i.id !== itemId);

			// We only need 3, so if there was not a redundant item, just pop the last off.
			if (items.length === 4) items.pop();

			return items;
		},
		queryKey: ['YMALItems', itemId]
	});

	$: YMALItems = $query.data ?? [];
	$: shouldDisplaySection = $query.isLoading || ($query.isSuccess && YMALItems.length > 0);
</script>

{#if shouldDisplaySection}
	<SectionWrapper classes={padding} testid="side-panel__you-may-also-like">
		<h3 slot="header" class="px-6 text-base">You may also like</h3>

		{#if $query.isLoading}
			<ul class="flex flex-col space-y-1">
				<!-- eslint-disable-next-line svelte/require-each-key, eslint-disable-next-line @typescript-eslint/no-unused-vars -->
				{#each Array(3) as __}
					<div class="h-[76px] w-full px-1">
						<div class="skeleton h-full w-full rounded-token"></div>
					</div>
				{/each}
			</ul>
		{:else if $query.isError}
			<ErrorState classes="p-4" />
		{:else}
			<ul class="flex flex-col">
				{#each YMALItems as item (item.id)}
					<li>
						<button
							data-id={item.id}
							type="button"
							class="btn flex h-full w-full items-start justify-start whitespace-normal rounded-none !py-2 px-2 text-left text-sm hover:variant-filled-surface"
							on:click={() => dispatchYMALItemClick({ item })}
						>
							<div class="h-14 w-14 !shrink-0">
								<Avatar
									border={item.type === 'UPDATE'
										? 'border border-primary-500'
										: ''}
									classes="!shrink-0"
									height="h-14"
									path={getAvatarPath(item)}
									rounded="rounded-xl"
									width="h-14"
								/>
							</div>

							<!-- BODY -->
							<article class="flex flex-col">
								<!-- Title -->
								<p class="line-clamp-2 font-bold text-surface-50">
									{item.title}
								</p>

								<!-- Votes / comments -->
								<div class="flex gap-2 text-on-surface-token">
									<p>{item._count.votes} votes</p>
									<span> &#183;</span>
									<p>{item._count.comments} comments</p>
								</div>
							</article>
						</button>
					</li>
				{/each}
			</ul>
		{/if}

		<slot name="footer" />
	</SectionWrapper>
{/if}
