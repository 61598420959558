<script context="module" lang="ts">
	export type AuthorInfoSocials = {
		linkedIn: { url: null | string } | null;
		website: { url: null | string } | null;
	} | null;
</script>

<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { generateLinkToVisitAuthorsPage } from '@tickrr/lib/utils';

	import { tippy } from '../../../../../../actions';
	import AuthorAvatar from '../../../feed/_internal/AuthorAvatar.svelte';
	import SectionWrapper from './SectionWrapper.svelte';

	export let itemType: FeedItemType;
	export let authorName: string;
	export let authorUsername: string;
	export let socials: AuthorInfoSocials;
	export let authorAvatarUrl: null | string;
	export let subAuthor: null | string = null;
	export let bio: null | string = null;

	$: redirectUrl = generateLinkToVisitAuthorsPage({
		authorUsername,
		feedItemType: itemType
	});
	$: hasASocial = Object.values(socials ?? {}).some(
		(i) => i !== null && i.url !== null && i.url !== ''
	);
</script>

<SectionWrapper classes="gap-0 !py-0 overflow-hidden" testid="side-panel__author-info">
	<h3 class="sr-only">Author details</h3>

	<header class="overflow-hidden">
		<a
			data-testid="side-panel__author-info__author-link"
			href={redirectUrl}
			class="btn flex h-full w-full items-center justify-start rounded-none p-4 text-left text-sm hover:variant-filled-surface"
		>
			<AuthorAvatar
				height="h-12"
				publisherAvatarUrl={authorAvatarUrl}
				rounded="rounded-token"
				testid="author-info-dropdown__author-avatar"
				width="w-12"
			/>

			<div
				data-tippy-content={authorName}
				data-tippy-placement="top"
				class="overflow-hidden"
				use:tippy
			>
				<!-- Full name -->
				<p
					data-testid="side-panel__author-info__author-name"
					class="truncate font-bold tracking-tight text-surface-50"
				>
					{authorName}
				</p>

				<!-- Username -->
				<p
					data-testid="side-panel__author-info__author-username"
					class="truncate text-on-surface-token"
				>
					@{authorUsername}
				</p>
			</div>
		</a>
	</header>

	<!-- 
		These should correspond to any *potential* values that may appear below.
		If any one of them is present, we render the divider and the details section.
	-->
	{#if (subAuthor && subAuthor !== authorName) || bio || hasASocial}
		<hr class="my-0 border-t-2 !border-surface-300/25" />

		<div class="space-y-4 p-4">
			<!-- UPPER  -->
			{#if (subAuthor && subAuthor !== authorName) || bio}
				<div class="space-y-2">
					<!-- Bio -->
					{#if bio}
						<blockquote
							class="blockquote line-clamp-5 border-l-2 border-base pl-2 pr-2 text-sm not-italic leading-4 !text-inherit"
						>
							<span>{bio}</span>
						</blockquote>
					{/if}

					<!-- Author -->
					{#if subAuthor && subAuthor !== authorName}
						<p class="truncate">
							<strong>Author: </strong>
							<span>{subAuthor}</span>
						</p>
					{/if}
				</div>
			{/if}

			<!-- LOWER -->
			{#if hasASocial}
				{@const websiteUrl = socials?.website?.url}
				{@const linkedInUrl = socials?.linkedIn?.url}
				<!-- <hr class="my-0 border-t-2 !border-surface-300/25" /> -->

				<div class="flex flex-wrap gap-2">
					<!-- Website -->
					{#if websiteUrl && websiteUrl !== ''}
						<a
							href={websiteUrl}
							rel="noopener noreferrer"
							target="_blank"
							class="variant-filled-surface btn !btn-sm rounded-lg hover:ring-1 hover:ring-surface-50"
							use:tippy={{ content: 'View Website', placement: 'top' }}
						>
							<iconify-icon icon="solar:link-bold"></iconify-icon>
							<span>View website</span>
						</a>
					{/if}

					<!-- LinkedIn -->
					{#if linkedInUrl && linkedInUrl !== ''}
						<a
							href={linkedInUrl}
							rel="noopener noreferrer"
							target="_blank"
							class="variant-filled-surface btn !btn-sm rounded-lg hover:ring-1 hover:ring-surface-50"
							use:tippy={{ content: 'View LinkedIn', placement: 'top' }}
						>
							<iconify-icon icon="skill-icons:linkedin"></iconify-icon>
							<span>View Linkedin</span>
						</a>
					{/if}
				</div>
			{/if}
		</div>
	{/if}
</SectionWrapper>
