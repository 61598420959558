<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { type PopupSettings, popup } from '@skeletonlabs/skeleton';
	import { generateLinkToVisitAuthorsPage } from '@tickrr/lib/utils';

	import FeedItemAuthorInfoAvatar from './AuthorAvatar.svelte';
	import AuthorInfoDropdown from './AuthorInfoDropdown.svelte';

	// Props...
	export let feedItemId: string;
	export let feedItemType: FeedItemType;
	export let authorName: string;
	export let authorUsername: string;
	export let authorAvatarUrl: null | string;

	let dropdownIsOpen: boolean = false;

	const authorInfoDropdownSettings: PopupSettings = {
		closeQuery: 'a',
		event: 'click',
		state: (e) => {
			dropdownIsOpen = e.state;
		},
		target: `feed-item__author-info-dropdown-${feedItemId}`
	};

	function handleClick(e: Event) {
		e.preventDefault();
		e.stopPropagation();
	}

	function handleKeydown(e: KeyboardEvent) {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
	}

	$: redirectUrl = generateLinkToVisitAuthorsPage({
		authorUsername: authorUsername,
		feedItemType
	});
</script>

<!-- 
	We cannot stop propogation of the click event when using a link; it will disrupt
	Svelte's client-side navigation listener from handling the click and will therefore cause 
	a full page reload.
	
	Accordingly, we must define our own handler (see above), preventDefault and stopPropation 
	of the event, then invoke goto(...) ourselves to navigate to the author's page.
-->
<button
	type="button"
	class="
	group/authorInfo
	relative
	flex
	h-full
	items-center
	gap-x-2
	hover:before:absolute
	hover:before:-inset-2
	hover:before:z-10
	hover:before:bg-surface-300/10
	hover:before:transition-all
	hover:before:rounded-token
	[&>*]:pointer-events-none
	"
	on:click={handleClick}
	on:keydown={handleKeydown}
	on:click|preventDefault
	on:click|stopPropagation
	use:popup={authorInfoDropdownSettings}
>
	<!-- AVATAR -->
	<FeedItemAuthorInfoAvatar publisherAvatarUrl={authorAvatarUrl} />
	<!-- AUTHOR NAME & USERNAME -->
	<span data-testid="feed-item__author-name" class="line-clamp-1 !text-left">
		{authorName}
	</span>
</button>

<div class="relative z-50 border-red-500">
	<AuthorInfoDropdown
		{authorAvatarUrl}
		{authorName}
		{authorUsername}
		{feedItemId}
		{feedItemType}
		isOpen={dropdownIsOpen}
		{redirectUrl}
	/>
</div>
