<!--
	@component

	FEED ITEM VIEWER / ITEM PAGE - Side Panel

	@prop itemType - The type of feed item that is currently being viewed.
	@prop itemId - The ID of the feed item that is currently being viewed.
	@prop title - The title of the feed item that is currently being viewed.
	@prop publishedAt - The date the feed item was published.
	@prop publisherName - The name of the publisher of the feed item.
	@prop publisherUsername - The username of the publisher of the feed item.
	@prop publisherAvatar - The avatar of the publisher of the feed item.
	@prop authorName - The name of the author of the feed item.
	@prop bio - The bio of the author of the feed item.
	@prop socials - The socials of the author of the feed item.

-->
<script lang="ts">
	import type { FeedItemType } from '@tickrr/lib/types';

	import { page } from '$app/stores';

	import { SignedIn, SignedOut } from '../../../../../../auth';
	import { getStores } from '../../../../../../stores';
	import CTAExtensionCard from '../../../marketing/CTAExtensionCard.svelte';
	import CTASignUpCard from '../../../marketing/CTASubscribeCard.svelte';
	import AuthorInfo, { type AuthorInfoSocials } from './AuthorInfo.svelte';
	import DatePublished from './DatePublished.svelte';
	import ThirdPartyShareButtons from './ThirdPartyShareButtons.svelte';
	import TrendingTickerList from './TrendingTickerList.svelte';
	import YouMayAlsoLike from './YouMayAlsoLike.svelte';

	const { isExtensionStore } = getStores(['isExtensionStore']);

	export let itemType: FeedItemType;
	export let itemId: string;
	export let title: string;
	export let publishedAt: Date;
	export let publisherName: string;
	export let publisherUsername: string;
	export let publisherAvatar: null | string;
	export let socials: AuthorInfoSocials;
	export let authorName: null | string;
	export let bio: null | string;
</script>

<!-- MAIN SECTION -->
<div
	class="flex h-full flex-col gap-y-6 tablet:gap-y-4"
	class:tablet:pt-6={$page.url.pathname.startsWith('/i/')}
>
	<DatePublished {publishedAt} />

	{#if itemType === 'ARTICLE'}
		<AuthorInfo
			authorAvatarUrl={publisherAvatar}
			authorName={publisherName}
			authorUsername={publisherUsername}
			{itemType}
			{socials}
			subAuthor={authorName}
		/>
	{:else}
		<AuthorInfo
			authorAvatarUrl={publisherAvatar}
			authorName={publisherName}
			authorUsername={publisherUsername}
			{bio}
			{itemType}
			{socials}
		/>
	{/if}

	<ThirdPartyShareButtons {itemId} {title} />

	<YouMayAlsoLike {itemId} on:YMALClick />

	<TrendingTickerList />

	<SignedOut>
		<CTASignUpCard background="bg-surface-800" />
	</SignedOut>
	<SignedIn>
		{#if !$isExtensionStore}
			<CTAExtensionCard background="bg-surface-800" />
		{/if}
	</SignedIn>
</div>
