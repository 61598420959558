<script lang="ts">
	import _ from 'lodash';

	// Props...
	export let pubDate: Date;
	export let readingTime: null | number;
	export let displayReadingTime: boolean = true;
	export let displayDate: boolean = true;
	export let classes: string = '';

	// For converting stringifed dates stored in Redis.
	$: pubDate = new Date(pubDate);
</script>

<!-- 
	@component
	
	FEED ITEM - DATE & READING TIME
	
	Displays the reading time of a Feed Item (articles, posts).

	@prop publishedAt - The date the feed item was published.
	@prop readingTime - The reading time of the feed item.
 -->
{#if displayReadingTime || displayDate}
	<div
		data-testid="feed-item__date-and-reading-time"
		class="ml-0 flex w-full items-center gap-x-2 align-middle {classes}"
	>
		{#if displayDate}
			<span>
				{pubDate.toLocaleDateString(undefined, {
					day: 'numeric',
					month: 'long'
				})}
			</span>
		{/if}

		{#if displayReadingTime}
			<span class="font-bold"> &#x2022; </span>

			<span> {readingTime ?? _.random(3, 8)} min read </span>
		{/if}
	</div>
{/if}
