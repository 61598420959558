<!-- 
	@component
	
	MARKDOWN EDITOR

	Displays a textarea input for markdown content and a preview panel.

	@prop content - The markdown content to display in the textarea.
	@prop rows - The number of rows to display in the textarea.
	@prop errorMessage - An error message to display below the textarea.
	@prop testIds - Test IDs for the textarea and submit button.
	@prop shouldFocusOnMount - Whether or not the textarea should be focused when the component mounts.
	@prop borderColor - The border color for the textarea and preview panel.
	@prop actionBarClasses - Additional classes to apply to the action bar.
-->
<script context="module" lang="ts">
	export const ACTION_ICON_CLASSES =
		'btn-icon btn-icon-sm rounded-md hover:variant-filled-surface';
	const NEW_LINK_TEXT = '[Link Text](https://)';
	const NEW_IMAGE_TEXT = '![Alt Text](https://)';
</script>

<script lang="ts">
	import { Tab, TabGroup } from '@skeletonlabs/skeleton';
	import { transformUserMarkdown } from '@tickrr/lib/utils';
	import { onMount } from 'svelte';

	import {
		focusAndOpenVirtualKeyboard,
		preventArrowKeyEventsFromPropogating,
		tippy
	} from '../../../../actions';
	import { SignedIn, SignedOut } from '../../../../auth';
	import { getStores } from '../../../../stores';
	import JoinNowButton from '../buttons/JoinNowButton.svelte';
	import Markdown from '../markdown/Markdown.svelte';

	const { isMobileStore } = getStores(['isMobileStore']);

	// Props...
	export let content: string = '';
	export let rows: number = 10;
	export let errorMessage: null | string = null;
	export let testIds: { input?: string } = { input: 'markdown-editor' };
	export let focusOnMount: boolean = false;

	export let borderColor: string = 'border-base';
	export let actionBarClasses: string = '';

	// Expose the textarea element to the parent component.
	export let el: HTMLTextAreaElement | null = null;

	// State...
	let viewSet: string = 'draft';

	function addText(text: string) {
		const selectionStart = el?.selectionStart;
		const selectionEnd = el?.selectionEnd;
		const newContent = `${content.slice(0, selectionStart)}${text}${content.slice(selectionEnd)}`;
		content = newContent;
		return;
	}

	onMount(() => {
		const tabPanel = document.querySelector('.tab-panel');
		tabPanel?.removeAttribute('tabindex');
		if (focusOnMount) {
			el?.focus();
		}
	});
</script>

<slot name="popup" />

<!-- MAIN COMPONENT -->
<TabGroup
	active="relative text-white [&>.tab]:!bg-surface-300/25 after:absolute after:bottom-0 after:h-[2px] after:bg-surface-900-50-token after:left-1/2 after:-translate-x-1/2 after:w-5 after:rounded-full"
	border="border-b {borderColor}"
	padding="p-3"
	regionList="[&>label>.tab]:px-2 [&>label>.tab]:py-1 [&>label>.tab]:rounded-md"
	regionPanel="!mt-0 !mb-0"
	spacing="space-y-3"
	class="space-y-0"
>
	<Tab name="draft" value="draft" bind:group={viewSet}>
		<span>Draft</span>
	</Tab>
	<Tab name="preview" value="preview" bind:group={viewSet}>
		<span>Preview</span>
	</Tab>

	<slot name="tab-trail" />

	<svelte:fragment slot="panel">
		{#if viewSet === 'draft'}
			<slot name="title" />

			<textarea
				bind:this={el}
				id="markdown-editor"
				data-testid={testIds.input}
				placeholder="Share your thoughts..."
				{rows}
				class="textarea relative rounded-none !border-0 !bg-transparent !ring-0"
				bind:value={content}
				on:keydown|stopPropagation
				on:keypress|stopPropagation
				on:keyup|stopPropagation
				use:focusAndOpenVirtualKeyboard={{
					clickDelay: 0,
					enabled: $isMobileStore && focusOnMount,
					initialDelay: 500
				}}
				use:preventArrowKeyEventsFromPropogating
			/>

			<slot name="additional-draft-panel-content" />
		{:else if viewSet === 'preview'}
			<slot name="preview">
				<div class="max-h-80 min-h-[136px] overflow-y-scroll p-4">
					{#if content}
						<Markdown source={transformUserMarkdown(content, { config: 'COMMENT' })} />
					{:else}
						<p class="italic">No content provided.</p>
					{/if}
				</div>
				<slot name="additional-preview-panel-content" />
			</slot>
		{/if}
	</svelte:fragment>
</TabGroup>

<!-- MOBILE ERROR MESSAGE -->
{#if errorMessage}
	<p class="h-fit p-4 pt-0 text-error-500 tablet:hidden">
		{errorMessage}
	</p>
{/if}

<!-- PRE-ACTION BAR -->
<slot name="pre-action-bar"><!-- optional fallback --></slot>

<!-- ACTION BAR -->
<div
	data-testid="markdown-action-bar"
	class="flex items-center justify-between border-t max-tablet:flex-wrap {borderColor} p-3 {actionBarClasses}"
>
	<SignedOut>
		<JoinNowButton classes="ml-auto" />
	</SignedOut>

	<SignedIn>
		<div class="flex items-center gap-x-2">
			<slot name="actions-lead" />

			<a
				href="https://www.markdownguide.org/cheat-sheet/"
				rel="noopener noreferrer"
				target="_blank"
				class={ACTION_ICON_CLASSES}
				use:tippy={{ content: 'View Markdown Guide', placement: 'top' }}
			>
				<iconify-icon icon="tabler:markdown"></iconify-icon>
				<span class="sr-only"> Markdown Cheat Sheet </span>
			</a>

			<button
				type="button"
				class={ACTION_ICON_CLASSES}
				on:click={() => addText(NEW_LINK_TEXT)}
				use:tippy={{ content: 'Insert link', placement: 'top' }}
			>
				<iconify-icon icon="solar:link-bold"></iconify-icon>
				<span class="sr-only"> Add link </span>
			</button>

			<button
				type="button"
				class={ACTION_ICON_CLASSES}
				on:click={() => addText(NEW_IMAGE_TEXT)}
				use:tippy={{ content: 'Insert image', placement: 'top' }}
			>
				<iconify-icon icon="solar:camera-bold"></iconify-icon>
				<span class="sr-only"> Add image </span>
			</button>
		</div>

		<div class="flex items-center gap-x-2">
			{#if errorMessage}
				<p class="h-fit p-4 pt-0 text-error-500 tablet:hidden">
					{errorMessage}
				</p>
			{/if}
			<slot name="actions-trail" />
		</div>
	</SignedIn>
</div>
