<!-- 
    MARKDOWN LINK RENDERER
    @see https://github.com/pablo-abc/svelte-markdown
-->
<script lang="ts">
	export let href = '';
	export let title: string | undefined = undefined;
	export let text = '';

	$: isTag = text.startsWith('#');
	$: isInstrument = text.startsWith('$');
</script>

<a {href} {title} class="anchor" class:md-instrument={isInstrument} class:md-tag={isTag}>
	{text}
</a>

<style lang="postcss">
	.md-tag,
	.md-instrument {
		text-decoration-line: none;
		&:hover {
			text-decoration-line: underline;
		}
	}
</style>
