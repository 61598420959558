/**
 * Returns the total number of replies to a given comment, including nested replies.
 * @param head - The comment to count replies for.
 * @param nodesGroupedById - An object mapping comment IDs to arrays of their child comments.
 * @returns The total number of replies to the given comment.
 */
export function getDescendantCount<T extends { id: string }>(
	head: T,
	nodesGroupedById: Record<string, T[]>
): number {
	let count = 0;
	const stack: T[] = [head];

	while (stack.length > 0) {
		const current = stack.pop();

		if (!current) continue;

		const children: T[] = nodesGroupedById[current.id] ?? [];

		count += children.length;

		for (const child of children) {
			stack.push(child);
		}
	}

	return count;
}
