/**
 * Scrolls to the given node and focuses it.
 * Waits 500ms before focusing to allow for smooth scroll, and
 * 500ms after focusing to allow for smooth focus.
 * @param node
 */
export function scrollAndAutoFocus(node: HTMLElement, initialDelay: number): void {
	setTimeout(() => {
		node.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'nearest' // default
		});
		setTimeout(() => {
			node.focus();
		}, 500);
	}, initialDelay);
}
