<script lang="ts">
	import { getModalStore } from '@skeletonlabs/skeleton';

	const modalStore = getModalStore();

	function closeModal() {
		modalStore.clear();
	}
</script>

<!-- close button -->
<button
	type="button"
	class="btn py-3 rounded-token tablet:variant-ghost hover:!ring-surface-50"
	on:click|stopPropagation={closeModal}
>
	<span class="inline-flex w-6 items-center justify-center">
		<iconify-icon icon="tabler:x" width="20" class="shrink-0"></iconify-icon>
	</span>
	<span class="sr-only">Close</span>
</button>
