<!-- 
	@component

	FEED CARD WRAPPER/CONTAINER (List Item)
	
	@prop mode - The mode of the feed (grid or list).
	@prop feedItemId - ID of the feed item.
	@prop pubDate - Date of the feed item.
	@prop testid - Test ID for the component.
	@prop disablePostViewerButton - Whether or not to disable the button that opens the Post Viewer modal.
	@prop height - Height of the component.
	@prop width - Width of the component.
	@prop bg - Background color of the component.
	@prop border - Border of the component.
	@prop shadow - Shadow of the component.
	@prop rounded - Border radius of the component.
	@prop variant - Variant of the component.
	@prop hover - Hover variant of the component.
	@prop padding - Padding of the component.
	@prop classes - Classes to add to the component.

-->
<svelte:options immutable={true} />

<script context="module" lang="ts">
	const gridClasses = {
		bg: 'card !bg-surface-700',
		border: 'border border-base hover:border-hover',
		height: 'min-h-[20rem]',
		padding: 'pt-4 pb-2',
		rounded: 'rounded-2xl',
		shadow: 'shadow-surfacce-900',
		width: 'w-full max-w-xs'
	};

	const listClasses = {
		bg: '!bg-component',
		border: 'border-y border-surface-300/10',
		height: 'h-full',
		padding: 'p-4',
		rounded: 'rounded-none',
		shadow: '',
		width: 'w-full'
	};
</script>

<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { dev } from '$app/environment';
	import { afterUpdate, createEventDispatcher } from 'svelte';

	import type { FeedLayout } from '../../../../../stores';

	import { flashOnUpdate } from '../../../../../actions';

	const dispatch = createEventDispatcher<{
		click: void;
	}>();

	// Props...
	export let feedItemId = '';
	export let testid = 'feed-item';
	export let pubDate: Date | undefined = undefined;
	export let upvoteCount: number | string | undefined = undefined;
	export let commentCount: number | string | undefined = undefined;
	export let disablePostViewerButton: boolean = false;
	export let disableFadeIn: boolean = false;

	export let mode: FeedLayout = 'GRID';

	export let height: CssClasses | null = null;
	export let width: CssClasses | null = null;
	export let bg: CssClasses | null = null;
	export let border: CssClasses | null = null;
	export let shadow: CssClasses | null = null;
	export let rounded: CssClasses | null = null;
	export let padding: CssClasses | null = null;

	export let variant: CssClasses = 'text-on-surface-token fill-on-surface-token';
	export let hover: CssClasses = 'hover:variant-filled-surface';
	export let classes: CssClasses = '';

	let el: HTMLElement;

	const dispatchClick = () => dispatch('click');

	const handleClick = (e: Event) => {
		dispatchClick();
		e.stopPropagation();
	};

	const handleKeydown = (e: KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			dispatchClick();
			e.stopPropagation();
		}
	};

	const useIfDefined = (value: CssClasses | null, defaultValue: CssClasses) => {
		return value ? value : defaultValue;
	};

	// Reactively update classes if/when FeedMode changes.
	// Setting these as props alone will not reactively update in response to changes made
	// after initial load.
	$: cAnimation = disableFadeIn ? '' : 'animate-fadeIn';
	$: cHeight = useIfDefined(height, mode === 'GRID' ? gridClasses.height : listClasses.height);
	$: cWidth = useIfDefined(width, mode === 'GRID' ? gridClasses.width : listClasses.width);
	$: cPadding = useIfDefined(
		padding,
		mode === 'GRID' ? gridClasses.padding : listClasses.padding
	);
	$: cBg = useIfDefined(bg, mode === 'GRID' ? gridClasses.bg : listClasses.bg);
	$: cBorder = useIfDefined(border, mode === 'GRID' ? gridClasses.border : listClasses.border);
	$: cShadow = useIfDefined(shadow, mode === 'GRID' ? gridClasses.shadow : listClasses.shadow);
	$: cRounded = useIfDefined(
		rounded,
		mode === 'GRID' ? gridClasses.rounded : listClasses.rounded
	);

	// This allows us to see each time a feed item is re-rendered.
	afterUpdate(() => {
		if (dev) {
			flashOnUpdate(el);
		}
	});
</script>

<!-- 
	Disable eslint/sort-attributes for this rule so that date-*
	attributes can be organized in their logical order (rather than alphabetically,
	as the linter would prefer).
-->
<!-- eslint-disable svelte/sort-attributes -->
<article
	bind:this={el}
	data-id={feedItemId}
	data-testid={testid}
	data-upvotes={upvoteCount}
	data-comments={commentCount}
	data-pub-date={pubDate ? new Date(pubDate).toISOString() : undefined}
	class="
	group
	text-surface-600-300-token
	relative
	min-w-full
	shrink
	cursor-pointer
	transition-all
	{cAnimation}
	{cHeight}
	{cWidth}
	{cBg}
	{cBorder}
	{cPadding}
	{cShadow}
	{cRounded}
	{variant}
	{hover}
	{classes}
"
>
	{#if !disablePostViewerButton}
		<button
			aria-label="Open Post Viewer Modal"
			type="button"
			class="
			absolute
			inset-0
			h-full
			w-full
			cursor-pointer
			text-left
			"
			on:click={handleClick}
			on:keydown={handleKeydown}
		/>
	{/if}

	<slot />
</article>
