<!--
	@component 

	COMMENT LIST

	Displays a list of comments. Rendered by PostViewerModal.

	@prop parentFeedItemType - The type of the Post being viewed.
	@prop parentFeedItemId - The ID of the Post being viewed.
	@prop comments - The comments array (taken from the Post selected in PostViewerModal).

-->
<script lang="ts">
	import type { FEComment } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import { TreeView } from '@skeletonlabs/skeleton';
	import _ from 'lodash';

	import CommentWithChildren from './CommentWithChildren.svelte';

	// Props
	export let parentFeedItemType: FeedItemType;
	export let parentFeedItemId: string;
	export let comments: FEComment[];

	// Prepare to re-construct tree.
	$: groupedComments = _.groupBy(comments, 'parent_id');
	$: headComments = groupedComments.null ?? []; // These comments have no parent.
</script>

<div class="max-tablet:mb-12">
	<TreeView
		hover="hover:bg-component focus-within:bg-component"
		indent="ml-0"
		regionChildren="empty:hidden"
		spacing="space-y-4"
	>
		{#each headComments as comment (comment.id)}
			{@const firstOrderChildren = groupedComments[comment.id] ?? []}
			<CommentWithChildren
				{comment}
				directDescendants={firstOrderChildren}
				{groupedComments}
				isHead
				{parentFeedItemId}
				{parentFeedItemType}
				on:commentUpdated
				on:replyAdded
				on:commentDeleted
			/>
		{/each}
	</TreeView>
</div>
