<script context="module" lang="ts">
	const INITIAL_TIMEOUT = 250;
</script>

<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { createEventDispatcher } from 'svelte';

	export let testid: string = 'confirm-action-buttons';
	export let classes: CssClasses = '';

	export let displayConfirmationButtons: boolean = false;
	let confirmButtonIsDisabled = true;

	const openConfirmationMenu = () => {
		displayConfirmationButtons = true;
		setTimeout(() => {
			confirmButtonIsDisabled = false;
		}, INITIAL_TIMEOUT);
	};

	const handleCancelClick = () => {
		displayConfirmationButtons = false;
		confirmButtonIsDisabled = true;
	};

	const dispatch = createEventDispatcher<{
		confirmClick: void;
	}>();

	function dispatchConfirmClick() {
		dispatch('confirmClick');
	}
</script>

{#if !displayConfirmationButtons}
	<slot {openConfirmationMenu} />
{:else}
	<div data-testid={testid} class="popup-menu-confirmation-buttons {classes}">
		<!-- CANCEL BUTTON -->
		<button
			aria-label="Cancel"
			type="button"
			class="w-full"
			on:click|stopPropagation={handleCancelClick}
		>
			<span>
				<iconify-icon icon="material-symbols:cancel-outline" />
			</span>
			<span class="sr-only"> Cancel </span>
		</button>

		<!-- CONFIRM BUTTON -->
		<button
			aria-label="Confirm"
			disabled={confirmButtonIsDisabled}
			type="button"
			class="w-full"
			on:click|stopPropagation={dispatchConfirmClick}
		>
			<span class="text-error-500">
				<iconify-icon icon="material-symbols:check-circle-outline" />
			</span>
			<span class="sr-only"> Confirm </span>
		</button>
	</div>
{/if}
