<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import TagBadge from '../../../feed/Tag.svelte';

	// Internal state
	export let tags: FEFeedItem['tags'];
	export let classes: string = '';

	// Sort tags with security_id to front.
	$: displayTags = tags.sort((a, b) => {
		if (a.instrument_symbol && !b.instrument_symbol) return -1;
		if (!a.instrument_symbol && b.instrument_symbol) return 1;
		return 0;
	});
</script>

<ul data-testid="feed-item-viewer__tags" class="my-4 flex flex-wrap gap-2 {classes}">
	{#each displayTags as tag (tag.id)}
		<li>
			<TagBadge isInstrument={!!tag.instrument_symbol} tagValue={tag.value} />
		</li>
	{/each}
</ul>
