<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import { transformUserMarkdown } from '@tickrr/lib/utils';

	import Markdown from '../../../../../elements/markdown/Markdown.svelte';
	import CommentGif from './Comment.Gif.svelte';

	const MAX_WORDS_BY_DEFAULT = 75;

	export let comment: FEComment;

	let content = transformUserMarkdown(comment.content, { config: 'COMMENT' });
	let words = content ? content.split(' ') : [];
	let contentToDisplay: string = '';

	let isWcOverLimit = words?.length > MAX_WORDS_BY_DEFAULT;
	let isExpanded: boolean = false;

	$: if (isWcOverLimit && !isExpanded) {
		contentToDisplay = words.slice(0, MAX_WORDS_BY_DEFAULT).join(' ') + ' ... ';
	} else {
		contentToDisplay = content || ''; // no need for Read More button if content is short.
	}
</script>

<div class="my-4">
	<div class="text-surface-900-50-token break-words leading-relaxed">
		<Markdown source={contentToDisplay} />

		{#if isWcOverLimit}
			<button
				type="button"
				class="anchor my-2"
				on:click|stopPropagation={() => (isExpanded = !isExpanded)}
			>
				{#if isExpanded}
					Read Less
				{:else}
					Read More
				{/if}
			</button>
		{/if}
	</div>

	{#if comment.giphy_url}
		<div class="mt-3">
			<CommentGif gifUrl={comment.giphy_url} />
		</div>
	{/if}
</div>
