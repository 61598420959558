<!--
	@component

	INTERACTION BUTTONS

	@prop itemType - The type of feed item being interacted with.
	@prop title - The title of the feed item.
	@prop itemId - The ID of the feed item.
	@prop authorId - The ID of the author of the feed item.
	@prop votes - The votes of the feed item.
-->
<script context="module" lang="ts">
	export const INTERACTION_BUTTON_CLASSES = {
		button: `group transition-all duration-150 hover:!text-white`,
		icon: `relative text-inherit`,
		// The text is handled differently when inside a container (i.e., on the feedItem page ('/i/:id').)
		text: `hidden h-6 laptop:inline @[0px]:laptop:hidden @5xl:laptop:inline`
	};
</script>

<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';
	import type { FeedItemType } from '@tickrr/lib/types';

	import VoteButtons from '../../../../feed/_internal/VoteButtons.svelte';
	import { scrollCommentInputIntoViewAndFocus } from '../comment-input/NewCommentForm.svelte';
	import AdditionalOptionsDropdown from './AdditionalOptionsDropdown.svelte';
	import BookmarkButton from './BookmarkButton.svelte';
	import CommentButton from './CommentButton.svelte';
	import ShareButton from './ShareButton.svelte';

	// Props...
	export let feedItemId: string;
	export let feedItemType: FeedItemType;
	export let title: string;
	export let authorId: null | string = null;
	export let upvoteCount: FEFeedItem['_count']['votes'];
	export let votes: FEFeedItem['votes'];
</script>

<div class="w-full">
	<section
		data-testid="feed-item-viewer__interaction-buttons"
		class="
		variant-ghost
		btn-group
		relative
		z-20
		mt-2
		h-[60.5px]
		w-full
		!overflow-visible
		!rounded-2xl
		ring-1
		!ring-surface-300/25
		[&>button>*]:!border-l-0
		[&>button]:w-full
		[&>button]:!border-0
		[&>button]:py-4
		[&>button]:text-sm
		[&>button]:font-bold
		[&>button]:max-[400px]:!px-2
		"
	>
		<VoteButtons {feedItemId} {feedItemType} bind:upvoteCount bind:votes />

		<CommentButton on:click={async () => await scrollCommentInputIntoViewAndFocus({})} />

		<BookmarkButton {feedItemId} />

		<ShareButton itemId={feedItemId} {title} />

		<AdditionalOptionsDropdown {authorId} {feedItemId} {feedItemType} />
	</section>
</div>
