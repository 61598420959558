<!-- 
	@component
	
	FEED ITEM VIEWER - IMAGE
	
	@prop feedItemId - The ID of the feed item.
	@prop feedItemOldId - The old ID of the feed item.
	@prop feedItemType - The type of feed item.
	@prop feedItemUrl - The URL of the feed item.
	@prop publisherUsername - The username of the publisher.
	@prop publisherName - The name of the publisher.
	@prop alt - The alt text of the feed item.
	@prop fallbackImg - The fallback image to use if no image is provided.

	
 -->
<script lang="ts">
	import FeedItemImage from './../../../../elements/images/TwicFeedItemImage.svelte';

	// Props
	export let imgPath: null | string;
	export let feedItemUrl: null | string;
	export let publisherUsername: string;
	export let publisherName: string;
	export let title: string;
	export let alt: string = '';
</script>

<div
	data-testid="fiv__image-container"
	class="
	relative
	mb-12
	mt-4
	aspect-video
	max-w-md
	overflow-hidden
	rounded-token
	tablet:mb-16
	tablet:mt-10
	"
>
	<a
		aria-label="Open Article in New Window"
		href={feedItemUrl}
		rel="noopener noreferrer"
		target="_blank"
		class="absolute inset-0 h-full w-full object-cover"
	>
		<FeedItemImage
			{alt}
			path={imgPath}
			publisher={{
				name: publisherName,
				username: publisherUsername
			}}
			{title}
		/>
	</a>
</div>
