<script lang="ts">
	import { calculateTimeSince } from '@tickrr/lib/utils';

	import { tippy } from '../../../../../../../actions';

	// Props
	export let createdAt: Date;
	export let classes: string = '';

	// Internal/computed state
	let timeSincePosted: null | string = null;

	// For converting stringifed dates stored in Redis.
	$: createdAt = new Date(createdAt);
	$: timeSincePosted = calculateTimeSince(createdAt);
</script>

<div
	data-tippy-content="Posted on {createdAt.toLocaleDateString(undefined, {
		day: 'numeric',
		month: 'long',
		weekday: 'long',
		year: 'numeric'
	})} at {createdAt.toLocaleTimeString(undefined, {
		hour: '2-digit',
		minute: '2-digit'
	})}"
	class="flex h-fit items-center gap-x-[4px] {classes}"
	use:tippy
>
	<iconify-icon inline icon="solar:clock-circle-linear" />

	<span>
		{timeSincePosted ?? 'unknown'}
	</span>
</div>
