<script lang="ts">
	// Props
	export let upvoteCount: number;
	export let commentCount: number;
</script>

<p class="mb-2 inline-flex gap-x-2">
	<span data-testid="feed-item-viewer__vote-count">
		{upvoteCount >= 0 ? upvoteCount : 0} Upvotes
	</span>
	<span> &#183; </span>
	<span data-testid="feed-item-viewer__comment-count">
		{commentCount} Comments
	</span>
</p>
