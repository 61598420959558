<script lang="ts">
	import { tippy } from '../../../../../../../actions';
	import { INTERACTION_BUTTON_CLASSES } from './InteractionButtons.svelte';
</script>

<!-- 
	@component
	
	COMMENT BUTTON
 -->
<button
	name="add-new-comment-button"
	aria-label="Add new comment"
	data-testid="feed-item-viewer__comment-button"
	data-tippy-content="Add comment"
	data-tippy-placement="bottom"
	type="button"
	class="{INTERACTION_BUTTON_CLASSES.button} !rounded-tl-token !rounded-bl-token"
	on:click
	use:tippy
>
	<span class={INTERACTION_BUTTON_CLASSES.icon}>
		<iconify-icon icon="solar:chat-line-outline" height="24px" />
	</span>

	<span class={INTERACTION_BUTTON_CLASSES.text}> Comment </span>
</button>
