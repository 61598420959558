<script lang="ts">
	// Props
	export let title: string;
</script>

<!-- TITLE  -->
<h2
	data-testid="feed-item-viewer__title"
	class="
	text-surface-900-50-token
	h2
	inline-flex
	overflow-hidden
	text-balance
	font-bold
	tablet:text-3xl
	"
>
	<span>
		{title}
	</span>
</h2>
