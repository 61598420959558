<!-- 
	@component
	
	SHARE BUTTON

	Single button, displaying a common-looking 'share' icon.
	Used, for example, in the footer of Posts and Articles to allow users to share
	the post to Facebook, Twitter, etc.

	@prop itemType - The type of item being shared (e.g., 'post', 'article', etc.)
	@prop itemId - The ID of the item being shared
	@prop title - The title of the item being shared
	
 -->

<script lang="ts">
	import { page } from '$app/stores';
	import { getToastStore } from '@skeletonlabs/skeleton';
	import { useQueryClient } from '@tanstack/svelte-query';
	import { generateShareableLink, generateWebshareData } from '@tickrr/lib/utils';

	import { clipboard, tippy, webshare } from '../../../../../../../actions';
	import { getStores } from '../../../../../../../stores';
	import { INTERACTION_BUTTON_CLASSES } from './InteractionButtons.svelte';

	const queryClient = useQueryClient();
	const toastStore = getToastStore();
	const { isExtensionStore, isWebshareSupportedStore } = getStores([
		'isExtensionStore',
		'isWebshareSupportedStore'
	]);

	// Props...
	export let itemId: string;
	export let title: string;

	// State...
	let linkHasBeenCopied = false;

	async function countShareInOnboardingChecklist() {
		if (!$page.data.profile?.is_onboarding_completed) {
			await queryClient.refetchQueries(['onboardingChecklist']);
		}
	}

	/**
	 * Copy Link Button - Click
	 */
	async function handleClick() {
		// We only need to track the click if a user is on a device that supports Web Share;
		// the WebShare API will handle the rest.
		if ($isWebshareSupportedStore) {
			await countShareInOnboardingChecklist();
			return;
		}

		linkHasBeenCopied = true;

		toastStore.trigger({
			message: 'Link copied to clipboard!'
		});

		await countShareInOnboardingChecklist();

		setTimeout(() => {
			linkHasBeenCopied = false;
		}, 2000);
	}

	$: shareableLink = generateShareableLink({ isExtension: $isExtensionStore, itemId });
	// TODO: re-enable this if disabling does not fix Copy button issue
	// TODO: (copying url AND title AND description)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	$: shareData = generateWebshareData({ itemId, title });
</script>

<button
	name="share-post-button"
	aria-label="Share post to other social sites"
	data-testid="feed-item-viewer__share-button"
	disabled={linkHasBeenCopied}
	type="button"
	class={INTERACTION_BUTTON_CLASSES.button}
	on:click={handleClick}
	use:clipboard={{ content: shareableLink, enabled: !$isWebshareSupportedStore }}
	use:tippy={{ content: 'Share', placement: 'bottom' }}
	use:webshare={{ enabled: $isWebshareSupportedStore, shareData: { url: shareableLink } }}
>
	{#if linkHasBeenCopied}
		<span class="hidden laptop:inline">Copied</span>
		<iconify-icon icon="tabler:checks" width="20" />
	{:else}
		<span class={INTERACTION_BUTTON_CLASSES.icon}>
			<iconify-icon
				icon="solar:multiple-forward-right-outline"
				height="24px"
				class="text-inherit"
			/>
		</span>
		<span class={INTERACTION_BUTTON_CLASSES.text}> Share </span>
	{/if}
</button>
