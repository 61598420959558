const DEFAULT_URL = 'https://tickrr.io';
/**
 * Generates a shareable link for a given item type and ID.
 * @param itemId The ID of the item to generate a link for.
 * @returns A shareable link for the given item type and ID.
 */
export function generateShareableLink({
	isExtension,
	itemId
}: {
	isExtension: boolean;
	itemId: string;
}): string {
	let baseUrl: string;
	// Try/catch here because location is not defined during SSR.
	try {
		baseUrl = isExtension ? DEFAULT_URL : location.origin;
	} catch {
		baseUrl = DEFAULT_URL;
	}

	return `${baseUrl}/i/${itemId}`;
}
