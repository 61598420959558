import type { FeedItemType } from '../types';

/**
 * Generates a link to visit a user's profile or publisher page based on the feed item type.
 * @param authorUsername - The username of the author whose profile or publisher page to link to.
 * @param feedItemType - The type of feed item (e.g. ARTICLE, COMMENT, etc.).
 * @returns A string representing the URL to the user's profile or publisher page.
 */
export function generateLinkToVisitAuthorsPage({
	authorUsername,
	feedItemType
}: {
	authorUsername: string;
	feedItemType: FeedItemType;
}) {
	switch (feedItemType) {
		case 'DAILY_DISCUSSION':
		case 'UPDATE':
			return `/changelog`;
		case 'ARTICLE':
			return `/publisher/${authorUsername}`;
		case 'POST':
			return `/profile/${authorUsername}`;
		default:
			throw new Error(`Invalid feed item type: ${feedItemType}`);
	}
}
