<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import DateAndReadingTime from '../../../feed/_internal/DateAndReadingTime.svelte';
	import Content from './Content.svelte';
	import FeedItemViewerHeader from './Header.svelte';
	import Image from './Image.svelte';
	import Tags from './Tags.svelte';
	import Title from './Title.svelte';
	import Tldr from './Tldr.svelte';
	import VoteAndCommentCount from './VoteAndCommentCount.svelte';

	// Props
	export let feedItemType: FEFeedItem['type'];
	export let imgPath: null | string;

	export let title: FEFeedItem['title'];
	export let tldr: FEFeedItem['tldr'];
	export let content: FEFeedItem['content'];
	export let readingTime: FEFeedItem['reading_time'];
	export let url: FEFeedItem['url'];
	export let publishedAt: FEFeedItem['pub_date'];

	export let publisherName: string;
	export let publisherUsername: string;
	export let publisherAvatarUrl: null | string;

	export let tags: FEFeedItem['tags'];
	export let upvoteCount: number = 0;
	export let commentCount: number = 0;
</script>

<section class="text-surface-600-300-token relative z-10 tablet:px-4 tablet:pt-4">
	<!-- 
		This el is necessary for our user onboarding tutorial. Without it, we can't
		focus/highlight this info without including the image, upvote count, etc.
	-->
	<div id="fiv__main-article-info" data-testid="fiv__main-article-info">
		<!--  HEADER -->
		<FeedItemViewerHeader
			classes="mb-4 px-2 tablet:hidden"
			{feedItemType}
			{publishedAt}
			{publisherAvatarUrl}
			{publisherName}
			{publisherUsername}
		/>

		<Title {title} />

		{#if !(feedItemType === 'POST')}
			<Tldr {content} {tldr} />
		{/if}

		<Content {content} {feedItemType} />

		{#if tags.length > 0}
			<Tags {tags} />
		{/if}
	</div>

	<DateAndReadingTime
		classes="mt-16 mb-4"
		displayDate
		displayReadingTime={feedItemType === 'ARTICLE'}
		pubDate={publishedAt}
		{readingTime}
	/>

	{#if !(feedItemType === 'POST' && !imgPath)}
		<Image
			alt={title}
			feedItemUrl={url}
			{imgPath}
			{publisherName}
			{publisherUsername}
			{title}
		/>
	{/if}

	<VoteAndCommentCount {commentCount} {upvoteCount} />
</section>
