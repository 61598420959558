<script lang="ts">
	import { tippy } from '../../../../../actions';
	import { FOOTER_BUTTON_CLASSES } from '../FeedItem.svelte';

	// Props..
	export let commentCount: null | number = null;
	export let classes = '';
</script>

<!--
	@component

	COMMENT BUTTON

	Single button, displaying a chat icon. 
	Used, for example, in the footer of Posts and Articles. 
	
	@prop commentCount - The number of comments on the feed item.
	@prop classes - The classes to apply to the component.
-->

<div class="flex items-center justify-center gap-x-0 {classes}">
	<button
		name="comment-button"
		aria-label="Add comment"
		data-testid="feed-item__comment-button"
		type="button"
		class="
		{FOOTER_BUTTON_CLASSES}
		hover:variant-soft-warning
		hover:!text-warning-500
		"
		on:click|stopPropagation
		use:tippy={{
			content: 'Comment'
		}}
	>
		<iconify-icon icon="solar:chat-line-outline" />
	</button>

	{#if commentCount !== null && commentCount > 0}
		<span class="text-base font-semibold leading-4">
			{commentCount}
		</span>
	{/if}
</div>
