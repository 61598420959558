<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import { Avatar } from '../../../../../elements';
	import CreatedAtDate from './Comment.CreatedAt.svelte';
	import UpdatedAtDate from './Comment.UpdatedAt.svelte';

	// Props
	export let comment: FEComment;
</script>

<header class="flex w-full items-center gap-x-4 text-sm">
	<!-- 1. -->
	<!-- A. -->
	<Avatar
		border="ring-2 ring-base"
		classes="shrink-0"
		height="h-10"
		path={comment.profile?.avatar_url}
		rounded="rounded-token"
		width="w-10"
	/>

	<!-- B. -->
	<div class="flex w-full flex-col overflow-hidden text-left">
		<div class="flex justify-between gap-x-4">
			<a
				href="/profile/{comment.profile.username}"
				class="block truncate"
				on:click|stopPropagation
			>
				<span class="w-full truncate font-bold text-surface-50">
					{comment.profile.name || comment.profile.username}
				</span>
			</a>
			<CreatedAtDate createdAt={comment.created_at} />
		</div>

		<div class="flex justify-between gap-x-4 overflow-hidden">
			<a
				href="/profile/{comment.profile.username}"
				class="block truncate"
				on:click|stopPropagation
			>
				<span>
					@{comment.profile.username}
				</span>
			</a>
			<UpdatedAtDate updatedAt={comment.updated_at} />
		</div>
	</div>
</header>
