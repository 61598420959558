type Options = {
	clickDelay?: number;
	enabled?: boolean;
	initialDelay?: number;
};

/**
 * Focuses on the given element and opens the virtual keyboard.
 * @param el - The element to focus on.
 * @param options - The options to use.
 *
 * @see https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus.
 */
export const focusAndOpenVirtualKeyboard = (el: HTMLElement | null, options?: Options) => {
	const enabled = options?.enabled ?? true;
	const initialDelay = options?.initialDelay ?? 0;
	const clickDelay = options?.clickDelay ?? 0;

	if (!enabled) {
		return;
	}

	setTimeout(() => {
		if (!el) {
			throw new Error('Element is null; cannot focus and open Virtual Keyboard.');
		}

		// Align temp input element approximately where the input element is
		// so the cursor doesn't jump around
		el.style.position = 'relative';
		const __tempEl__ = document.createElement('input');
		__tempEl__.style.position = 'absolute';
		__tempEl__.style.top = el.offsetTop + 7 + 'px';
		__tempEl__.style.left = el.offsetLeft + 'px';
		__tempEl__.style.height = `${0}`;
		__tempEl__.style.opacity = `${0}`;
		// Put this temp element as a child of the page <body> and focus on it
		document.body.appendChild(__tempEl__);
		__tempEl__.focus();

		// The keyboard is open. Now do a delayed focus on the target element
		setTimeout(() => {
			el.focus();
			el.click();
			// Remove the temp element
			document.body.removeChild(__tempEl__);
		}, clickDelay);
	}, initialDelay);
};
