<!-- 
    MARKDOWN LIST RENDERER
    @see https://github.com/pablo-abc/svelte-markdown
-->
<script lang="ts">
	export let ordered: boolean;
	export let start: number | undefined;

	const cStyles = `list my-4 pl-6 [&>li]:list-item [&>li]:!space-x-0`;
</script>

{#if ordered}
	<ol {start} class="{cStyles} list-inside list-decimal">
		<slot />
	</ol>
{:else}
	<ul class="{cStyles} list-inside list-disc">
		<slot />
	</ul>
{/if}
