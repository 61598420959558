<!-- 
	@component
	
	COMMENT - Footer
	
	@prop comment - The comment object.
	@prop replyInputIsDisplayed - Whether or not the reply input is displayed.
	@prop replyCount - The number of replies to the comment.
 -->
<script context="module" lang="ts">
	export let COMMENT_FOOTER_BUTTON_CLASSES = `
		flex items-center justify-center
		rounded-[10px] p-2
		transition-all 
	`;
</script>

<script lang="ts">
	import type { FEComment } from '@tickrr/db';

	import CommentAdditionalOptionsDropdown from './Comment.AdditionalOptionsDropdown.svelte';
	import ReplyButton from './Comment.ReplyButton.svelte';
	import UpvoteButton from './Comment.VoteButton.svelte';

	// Props
	export let comment: FEComment;
	export let parentFeedItemId: string;
	export let editMenuIsDisplayed: boolean;
	export let replyInputIsDisplayed: boolean;
	export let replyCount: number = 0;

	$: votes = comment.votes;
</script>

<footer class="flex items-center justify-between">
	<!-- Left side -->
	<div class="flex gap-x-2">
		<UpvoteButton
			commentId={comment.id}
			feedItemId={parentFeedItemId}
			upvoteCount={comment._vote_count}
			{votes}
		/>

		<ReplyButton bind:replyInputIsDisplayed />

		<CommentAdditionalOptionsDropdown
			authorId={comment.profile_id}
			commentId={comment.id}
			{parentFeedItemId}
			bind:editMenuIsDisplayed
			on:commentDeleted
		/>
	</div>

	<!-- Right side -->
	{#if replyCount > 0}
		<span data-testid="comment__reply-count">
			{replyCount} replies
		</span>
	{/if}
</footer>
